import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  textField : {
    marginRight: theme.spacing.unit,
  },
  paper: {
    paddingTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginTop : theme.spacing.unit,
  }
});


function GoogleReviews(props) {
  const { classes, reviews } = props;
  return (
    <Paper className={classes.paper}>
      <Typography variant="title" gutterBottom>Reviews</Typography>
      {reviews.map((review, reviewId) => (
        <Grid item xs={12} key={reviewId}>
            <Typography variant="body1" gutterBottom>Review #: {reviewId}</Typography>
            <TextField
                disabled
                id={review.author_name}
                label="Author name"
                variant="filled"
                type="string"
                value={review.author_name}
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.textField}
                margin="normal"
                />
            <TextField
                disabled
                id={review.author_url}
                fullWidth
                label="Author_url"
                variant="filled"
                type="string"
                value={review.author_url}
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.textField}
                margin="normal"
                />
            <TextField
                disabled
                id={review.language}
                label="Language"
                variant="filled"
                type="string"
                value={review.language}
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.textField}
                margin="normal"
                />
            <TextField
                disabled
                id={review.profile_photo_url}
                label="Profile_photo_url"
                variant="filled"
                type="string"
                value={review.profile_photo_url}
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.textField}
                margin="normal"
                fullWidth
                />
            <TextField
                disabled
                id="Rating"
                label="Rating"
                variant="filled"
                type="string"
                value={review.rating}
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.textField}
                margin="normal"
                />
            <TextField
                disabled
                id={review.relative_time_description}
                label="Relative_time_description}"
                variant="filled"
                type="string"
                value={review.relative_time_description}
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.textField}
                margin="normal"
                />
            <TextField
                disabled
                id={review.text}
                label="Text"
                variant="filled"
                type="string"
                value={review.text}
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.textField}
                margin="normal"
                fullWidth
                multiline
                />
            <TextField
                disabled
                id="time"
                label="Time"
                variant="filled"
                type="string"
                value={review.time}
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.textField}
                margin="normal"
                />
        </Grid>
        ))}
    </Paper>
  );
}

GoogleReviews.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GoogleReviews);