import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
      },
  });  

  function TabContainer(props) {
    return (
        <>
         {props.children}
        </>
    );
  }
  
 function MultiLangRoomInfo(props) {
     const { classes } = props;
     return (
        <>
         <AppBar color="default" position="static">
                <Tabs value={props.value} onChange={props.handleChangeTab}>
                  <Tab label="Room English" />
                  <Tab label="Room Ukraine" />
                  <Tab label="Room Russian" />
                </Tabs>
        </AppBar>
            {props.value === 0 && 
                <TabContainer>
                    <TextField
                        id="roomname_en"
                        label="Rome Name on English"
                        className={classes.textField}
                        onChange={props.handleChangeRoomName('en')}
                        value={props.roomname[0].value}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        required
                        id="food_en"
                        label="Food include for room on English"
                        className={classes.textField}
                        helperText="Example: Buffet breakfast include... etc"
                        onChange={props.handleChangeRoomFood('en')}
                        value={props.food[0].value}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        required
                        id="beds_en"
                        label="Beds in room on English"
                        variant="outlined"
                        helperText="Example: 2 queen beds ... etc"
                        className={classes.textField}
                        margin="normal"
                        onChange={props.handleChangeRoomBeds('en')}
                        value={props.beds[0].value}
                    />
                    <TextField
                        required
                        id="description_en"
                        label="Room Description on English"
                        variant="outlined"
                        fullWidth
                        value={props.description[0].value}
                        className={classes.textField}
                        margin="normal"
                        multiline={true}
                        helperText="Example: This quadruple room features a mini-bar, electric kettle and satellite TV."
                        rows={4}
                        rowsMax={8}
                        onChange={props.handleChangeDescriptionRoom("en")}
                        />
                        <TextField
                        required
                        id="rules_en"
                        label="Rules for room on English"
                        variant="outlined"
                        fullWidth
                        value={props.rules[0].value}
                        className={classes.textField}
                        margin="normal"
                        multiline={true}
                        rows={2}
                        rowsMax={4}
                        onChange={props.handleChangeRoomRules("en")}
                        />
                </TabContainer>
                                    }
              {props.value === 1 && 
                <TabContainer>
                    <TextField
                        id="roomname_uk"
                        label="Room name on Ukraine"
                        className={classes.textField}
                        onChange={props.handleChangeRoomName('uk')}
                        value={props.roomname[1].value}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        required
                        id="food_uk"
                        label="Food include for room on Ukraine"
                        variant="outlined"
                        className={classes.textField}
                        margin="normal"
                        helperText="Correct: сніданок шведьский стіл включено... etc"
                        onChange={props.handleChangeRoomFood('uk')}
                        value={props.food[1].value}
                    />
                    <TextField
                        required
                        id="beds_uk"
                        label="Beds in room on Ukraine"
                        variant="outlined"
                        helperText="Example: 2 королівських кроваті ... etc"
                        className={classes.textField}
                        margin="normal"
                        onChange={props.handleChangeRoomBeds('uk')}
                        value={props.beds[1].value}
                    />
                    <TextField
                        required
                        id="description_uk"
                        label="Room Description on Ukraine"
                        variant="outlined"
                        fullWidth
                        value={props.description[1].value}
                        className={classes.textField}
                        margin="normal"
                        helperText="Example: This quadruple room features a mini-bar, electric kettle and satellite TV."
                        multiline={true}
                        rows={4}
                        rowsMax={8}
                        onChange={props.handleChangeDescriptionRoom("uk")}
                        />
                    <TextField
                        required
                        id="rules_uk"
                        label="Rules for room on Ukraine"
                        variant="outlined"
                        fullWidth
                        value={props.rules[1].value}
                        className={classes.textField}
                        margin="normal"
                        multiline={true}
                        rows={2}
                        rowsMax={4}
                        onChange={props.handleChangeRoomRules("uk")}
                        />
                </TabContainer>}
              {props.value === 2 &&
                <TabContainer>
                    <TextField
                        id="roomename_ru"
                        label="Room Name on Russian"
                        className={classes.textField}
                        onChange={props.handleChangeRoomName('ru')}
                        value={props.roomname[2].value}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        required
                        id="food_ru"
                        label="Food include for room on Russia"
                        variant="outlined"
                        className={classes.textField}
                        margin="normal"
                        helperText="Example: завтрак шведский стол включено... etc"
                        onChange={props.handleChangeRoomFood('ru')}
                        value={props.food[2].value}
                    />
                    <TextField
                        required
                        id="beds_ru"
                        label="Beds in room on Russian"
                        variant="outlined"
                        helperText="Example: 2 королевских кровати ... etc"
                        className={classes.textField}
                        margin="normal"
                        onChange={props.handleChangeRoomBeds('ru')}
                        value={props.beds[2].value}
                    />
                    <TextField
                        required
                        id="description_ru"
                        label="Roome Description on Russian"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        value={props.description[2].value}
                        margin="normal"
                        helperText="Example: This quadruple room features a mini-bar, electric kettle and satellite TV."
                        multiline={true}
                        rows={4}
                        rowsMax={8}
                        onChange={props.handleChangeDescriptionRoom("ru")}
                        />
                        <TextField
                        required
                        id="rules_ru"
                        label="Rules for room on Russian"
                        variant="outlined"
                        fullWidth
                        value={props.rules[2].value}
                        className={classes.textField}
                        margin="normal"
                        multiline={true}
                        rows={2}
                        rowsMax={4}
                        onChange={props.handleChangeRoomRules("ru")}
                        />
                </TabContainer>}
        </>
     )
 }

 MultiLangRoomInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(MultiLangRoomInfo);