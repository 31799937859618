import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { compose } from 'redux'

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Language from '@material-ui/icons/Language';

import {changeLanguage} from '../_actions';

const styles = theme => ({
    paper: {
    },
  });

class ChangeLanguages extends Component {
    state = {
        anchorEl: null,
        lang : 'uk',
        open: false,
      };
  

    handleMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
      };
    
    handleMenuClose = () => {
        this.setState({ anchorEl: null, });
      };
    
    handleLangChoose = event => {
      const { lang } = event.target;
      const { dispatch } = this.props;
      dispatch(changeLanguage(lang));
      this.setState( { lang : lang, anchorEl: null, });
      
    }
    
    render () {
        const { classes,} = this.props;
        const { anchorEl, } = this.state;
        return (
            <React.Fragment>
                <Tooltip title="Изменить язык">
                    <IconButton 
                        onClick={this.handleMenuOpen} 
                        className={classes.menuButton} 
                        color="inherit" 
                        aria-label="Menu"
                        aria-owns={anchorEl ? 'language-menu' : undefined}
                        >
                        <Language />
                    </IconButton>
                </Tooltip>
                <Menu
                    id="language-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleMenuClose}
                    >
                        <MenuItem lang='uk' onClick={this.handleLangChoose}>Українська</MenuItem>
                        <MenuItem lang='ru' onClick={this.handleLangChoose}>Русский</MenuItem>
                        <MenuItem lang='en' onClick={this.handleLangChoose}>English</MenuItem>
     
                </Menu>
        </React.Fragment>
        )
    }
}

ChangeLanguages.propTypes = {
    classes: PropTypes.object.isRequired,
  };

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { registered } = state.registration;
    const { alert, } = state;
    return {
        loggingIn,
        alert,
        registered
    };
  }

const ChangeLanguagesCompose = compose(
    withStyles(styles),
    connect(mapStateToProps),
  )(ChangeLanguages);
export {ChangeLanguagesCompose as ChangeLanguages};