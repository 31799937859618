export function hotelState(state='nostate' , action) {
    switch (action.type) {
        case 'updated':
          return 'updated';
        case 'created':
          return 'created';
        case 'error':
            return 'error';
        case 'nostate':
          return 'nostate';
        default:
          return state;
      }
} 