import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    dense: {
      marginTop: 19,
    }, 
    listItem : {
      paddingTop : 0,
      paddingBottom : 0,
   },
   tableCell : {
     //maxWidth : 150,
   }

  });

function TableListOfHotels(props) {
    const { classes, page, rowsPerPage } = props;
    return (
      <>
        <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                      <TableCell>Hotels Name,City, Region, lat&lon</TableCell>
                      <TableCell align="left">Actions</TableCell>
                      <TableCell align="left">Hotel gallerry</TableCell>
                      <TableCell align="left">Room gallerry</TableCell>
                    </TableRow>
                </TableHead>
            <TableBody>
                {props.hotels.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((hotel, id) => (
                <TableRow key={id}>
                  
                  <TableCell component="th" scope="row" className={classes.tableCell}>
                      {Object.keys(hotel.hotelname).map((key,id) => (
                            <React.Fragment key={id}>
                              <Typography align="left" variant="body1">
                                {key}:{hotel.hotelname[key]}<br/>
                              </Typography>
                            </React.Fragment>
                        ))}
                      {hotel.city[0].value} <br/> {hotel.latitude}, {hotel.longitude}
                  </TableCell>

                  <TableCell align="left" className={classes.tableCell}>
                        <Button                        
                          size="small" 
                          color="primary"                         
                          variant="contained" 
                          className={classes.button} 
                          onClick={props.handleOpenUpdate(hotel.hotelname)}
                          >
                            Edit
                        </Button>
                        
                        <Button                        
                          size="small" 
                          color="secondary"                         
                          variant="contained" 
                          className={classes.button} 
                          onClick={props.handleClickOpenDialog(hotel.hotelname)}
                          >
                            Delete
                        </Button>
                    </TableCell>

                    <TableCell align="left" className={classes.tableCell}>
                      <Typography align="left" variant="body2">
                        We have {hotel.imgHotelPathArray.length} images for Hotel.
                      </Typography>
                    </TableCell>

                    <TableCell align="left" className={classes.tableCell}>
                      {hotel.roomstype.map((room, id) => (
                        <React.Fragment key={id}>
                          <Typography align="left" variant="body2">
                            We have {room.imgs.length} images for Room {room.roomname[0].value}
                          </Typography>
                        </React.Fragment>
                      ))}
                    </TableCell>
                </TableRow>
                ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.hotels.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={props.handleChangePage}
                onChangeRowsPerPage={props.handleChangeRowsPerPage}
              />
      </>
    )
}

TableListOfHotels.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  export default withStyles(styles)(TableListOfHotels);

  