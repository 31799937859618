import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux'

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import ModalAddRoom from './Modals/ModalAddRoom';
import ModalAddHotel from './Modals/ModalAddHotel';
import ModalError from './Modals/ModalError';
import ModalSuccess from './Modals/ModalSuccess';

import MultiLangHotelInfo from './MultiLangHotelInfo';
import MainInfoHotel from './MainInfoHotel';
import TypeOfAccommodation from './TypeOfAccommodation';
import {Services} from './Services';

import MultiLangRoomInfo from './Rooms/MultiLangRoomInfo';
import MainInfoRoom from './Rooms/MainInfoRoom';
import ShowRooms from './Rooms/ShowRooms';
import {ServicesRoom} from './Rooms/ServicesRoom';

import UploadImagesForHotel from './UploadImagesForHotel';
import UploadImagesForRoom from './Rooms/UploadImagesForRoom';

import {hotelsService} from '../_services';
import translate from '../HOC/translate';
import {changeStateHotel} from '../_actions';

const styles = theme => ({
    header :{
      padding: theme.spacing.unit,
    },
    input: {
      display: 'none',
    },
    button: {
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
    },
    buttonAddHotel: {
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
    },
    paperButton: {
      textAlign : "right",
    },
    paper: {
      padding: theme.spacing.unit * 2,
    },
    dense: {
      marginTop: 19,
    }, 
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    }
  });


class AddHotel extends Component {
    
  constructor(props) {
    super(props);
    this.state = {

      roomstype : [],

      roomstypeObj : 
          { roomname :  [
            { lang:'en', value: ''},
            { lang:'uk', value: ''},
            { lang:'ru', value: ''},
          ] ,
            description : [
              { lang:'en', value: ''},
              { lang:'uk', value: ''},
              { lang:'ru', value: ''},
            ] ,
            rules :  [
              { lang:'en', value: ''},
              { lang:'uk', value: ''},
              { lang:'ru', value: ''},
            ] ,
            food :  [
              { lang:'en', value: ''},
              { lang:'uk', value: ''},
              { lang:'ru', value: ''},
            ] ,
            beds:  [
              { lang:'en', value: ''},
              { lang:'uk', value: ''},
              { lang:'ru', value: ''},
            ] ,
            personcount: '1', size: '30', price : '500', services : [], 
            imgs : [],
          },

      imagesroom : [],
      food :  [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ] ,
      beds:  [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ] ,
      roomname :  [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ],
      descriptionRoom : [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ],
      rules :  [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ],
      servicesroom : [
        {name: 'Bathroom' ,
          items :[
            {name:'Shower', selected: false}, 
            {name:'Bathroom',selected: false},
            {name:'Bidet',selected: false}, 
            {name:'Hygienic shower',selected: false},
            {name:'Toilet',selected: false},
            {name:'Free toiletries',selected: false},
            {name:'Towels',selected: false},
            {name:'Bathrobe',selected: false},
            {name:'Hairdryer',selected: false},
            {name:'Disposable brushes',selected: false},
            {name:'Jacuzzi',selected: false},
            {name:'Washer',selected: false},],
        },    
        {name: 'Room Facilities' ,
          items :[
          {name: 'Minibar', selected: false},
          {name:'DVD Player',selected: false},
          {name:'Heating radiators',selected: false},
          {name:'Slippers',selected: false},
          {name:'Satellite channels',selected: false}, 
          {name:'Internet',selected: false},
          {name:'Flat-screen TV',selected: false},
          {name:'Hardwood floors',selected: false},
          {name:'Linens',selected: false},
          {name:'Mosquito net',selected: false},
          {name:'Wardrobe',selected: false},
          {name:'Writing/coffee table or closet',selected: false},
          {name:'Air conditioning',selected: false},
          {name:'Terrace',selected: false},
          {name:'Balcony',selected: false}, 
          {name:'Carpeted',selected: false},
          {name:'Floor windows',selected: false},
          {name:'Sofa couch',selected: false},
          {name:'Armchairs',selected: false}, 
          {name:'Electric kettle',selected: false}, 
          {name:'Microwave',selected: false}, 
          {name:'Fridge',selected: false}, 
          {name:'Plate',selected: false}, 
          {name:'Cutlery',selected: false}, 
        ],
        }
      ],
      description : [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ] ,
      city : [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ] ,
      region : [
        { lang:'en', value: 'Kiev Region'},
        { lang:'uk', value: 'Київська область'},
        { lang:'ru', value: 'Киевская область'},
      ] ,
      hotelname : {
          en:'',
          uk:'',
          ru:'',
        },
      typeOfAccommod : [
        {name: 'Cottage' , selected: false}, {name: 'Bungalow' , selected: false},
        {name: 'Main Building', selected: false}, {name: 'Villa', selected: false},
        {name: 'Tent' , selected: false}],
      services: [
        {name: 'Activities' ,
          items :[
            {name:'Beach', selected: false},
            {name:'Boat/catamaran rental', selected: false},
            {name:'GyroScooter rental', selected: false},
            {name:'Tennis court', selected: false},
            {name:'Kids\' club', selected: false},
            {name:'Playground', selected: false},
            {name:'Horseback riding', selected: false},
            {name:'Cycling routes', selected: false},
            {name:'Bicycle rental', selected: false},
            {name:'Darts', selected: false},
            {name:'Ping-pong', selected: false},
            {name:'Pool table', selected: false},
            {name:'Fishing', selected: false},
            {name:'Zoo', selected: false},
            {name:'Paintball', selected: false},
            {name:'Rope park', selected: false},
            {name:'Trampoline', selected: false},],
        },
        {name: 'Pool & SPA' ,
          items :[
            {name:'Solarium', selected: false},
            {name:'Massage', selected: false},
            {name:'SPA area', selected: false},
            {name:'Beauty parlor', selected: false},
            {name:'Bath: russian/finnish', selected: false},
            {name:'Sauna', selected: false},
            {name:'Pool', selected: false},
            {name:'Indoor pool', selected: false},
            {name:'Children\'s pool', selected: false},
            {name:'Heated swimming pool', selected: false}],
        },
        {name: 'Territory' ,
          items :[
            {name:'Forest', selected: false},
            {name:'Garden', selected: false},
            {name:'Terrace', selected: false},
            {name:'BBQ facilities', selected: false},
            {name:'Skewers for rent', selected: false},
            {name:'Altanka rental', selected: false},
            {name:'Altanka for free', selected: false},
            {name:'Benches', selected: false},
            {name:'Lawn', selected: false},
            {name:'Apiary', selected: false},
            {name:'Food shop', selected: false},
            {name:'Gift shop', selected: false},
            {name:'Pond', selected: false},
            {name:'Lake', selected: false},
            {name:'River', selected: false},],
        }, 
        {name: 'Food & Drink' ,
          items :[
            {name:'Restaurant', selected: false},
            {name:'Cafe', selected: false}, 
            {name:'Bar', selected: false},
            {name:'Hookah', selected: false},
            {name:'Diet food', selected: false},
            {name:'Alcoholic drinks', selected: false},
            {name:'Cooking on the grill', selected: false},
            {name:'Food from the oven', selected: false},
            {name:'Kids\' meals', selected: false},
            {name:'Self cooking', selected: false},
            {name:'Food delivery to the room', selected: false},],
        },
        {name: 'General' ,
          items :[
            {name:'Medical staff', selected: false},
            {name:'Smoking area', selected: false},
            {name:'Tour agency', selected: false},
            {name:'Express check-in/out', selected: false},
            {name:'Private check-in/out', selected: false}, 
            {name:'Terminal', selected: false},
            {name:'Payment by credit card', selected: false},
            {name:'24-hour front desk', selected: false},
            {name:'Guarded territory', selected: false},
            {name:'Conference hall', selected: false},
            {name:'Organization of events', selected: false}, 
            {name:'Parking charge', selected: false},
            {name:'Parking free', selected: false},
            {name:'Accommodation with animals', selected: false},
            {name:'Children\'s beds in the room', selected: false}, 
            {name:'Room cleaning', selected: false},
            {name:'Nanny', selected: false},],
        },
        {name: 'Languages Spoken' ,
          items :[
            {name:'English', selected: false},
            {name:'Russian', selected: false},
            {name:'Ukrainian', selected: false},],
        },
        {name: 'WiFi & Internet' ,
          items :[
            {name: 'In the territory', selected: false},
            {name: 'In the rooms', selected: false},
            {name: 'In the restaurant', selected: false},
            {name: 'In the main building', selected: false},],
        },
        // {name: 'Bathroom' ,
        //   items :[
        //   {name: 'Toilet paper', selected: false}, {name: 'Linens',selected: false},
        //   {name:'Towels',selected: false}, {name:'Bidet',selected: false},{name:'Slippers',selected: false},
        //   {name:'Private bathroom',selected: false}, {name:'Toilet',selected: false},{name:'Free toiletries',selected: false},
        //   {name:'Bathrobe',selected: false},{name:'Hairdryer',selected: false},{name:'Shower', selected: false}],
        // },
        // {name: 'Outdoors' ,
        //   items :[
        //   {name:'Sun deck', selected: false},{name:'Private beach area', selected: false},{name:'BBQ facilities', selected: false},
        //   {name:'Terrace', selected: false},{name:'Garden', selected: false},],
        // },
        // {name: 'Food & Drink' ,
        // items :[
        //   {name:'Wine/Champagne', selected: false},{name:'Kids\' meals', selected: false}, {name:'Special diet meals (on request)', selected: false},
        //   {name:'Breakfast in the room', selected: false},{name:'Bar', selected: false},{name:'Restaurant', selected: false},
        //   {name:'Minibar', selected: false},],
        // },
      //   {name: 'Front Desk Services' ,
      //   items :[
      //   {name:'Private check-in/out', selected: false},{name:'Tour desk', selected: false},
      //   {name:'Express check-in/out', selected: false}, {name:'24-hour front desk', selected: false},],
      // },
      // {name: 'Languages Spoken' ,
      // items :[
      //   {name:'English', selected: false},{name:'Russian', selected: false},{name:'Ukrainian', selected: false},],
      // },
      // {name: 'Internet' ,
      // items :[
      //   {name: 'Free! WiFi is available in the hotel rooms and is free of charge.', selected: false},
      //   {name: 'WiFi is not available in the hotel rooms.', selected: false},
      //   {name: 'WiFi is available in the hotel rooms. Additional charge', selected: false},],
      // },
      // {name: 'Parking' ,
      // items :[
      //   {name: 'Free public parking is available on site (reservation is needed).', selected: false},
      //   {name: 'Secure parking', selected: false},],
      // },
      // {name: 'Family' ,
      // items :[
      //   {name:'Babysitting/Child services', selected: false},
      //   {name:'Kids club', selected: false},],
      // },
      ],
      
      valueTabForHotel: 0,
      valueTabForRoom: 0,
      hotel : {
        hotelname : {},
        place_id: '',
        description : [] ,
        roomstype : [],
        city: [],
        region : [],
        email: '',
        phone: '',
        site: '',
        latitude: '12',
        longitude: '12',
        distance_from_city: '12',
        typeOfAccommod: [],
        services: [],
      },
      files : [],
      errorMsg : '',
      successMsg : '',
      errorDialogOpen: false,
      succesDialogOpen : false,
      dialogAddHotel : false,
      dialogAddRoom : false,
      }
    }

    //API handle create hotel, and upload img for hotel
  handleCreateNewHotel = () => {
    this.setState({
      hotel :{
        ...this.state.hotel,
        services : this.state.services,
        typeOfAccommod : this.state.typeOfAccommod,
        description : this.state.description,
        hotelname : this.state.hotelname,
        roomstype : this.state.roomstype,
        city : this.state.city,
        region : this.state.region,
        },
    }, () => {   
        const formData = new FormData();
        //add img's data to formData obj
        this.state.files.forEach(file => formData.append(this.state.hotelname.en, file));
        formData.append('hotelname', this.state.hotelname.en);

        const formDataForRoom = new FormData();
        formDataForRoom.append('hotelname', this.state.hotelname.en);
        this.state.hotel.roomstype.forEach(room => {
            formDataForRoom.append('roomname', room.roomname[0].value);
            room.imagesroom.forEach(file =>{
                  formDataForRoom.append(room.roomname[0].value, file);
                })
        });
          // I must send formDataForRoom to server
        console.log(this.state.hotel);
        hotelsService.create(this.state.hotel)
          .then(() => hotelsService.uploadImgHotel(formData).catch((err) => 
                        this.setState({errorMsg: err, errorDialogOpen: true, dialogAddHotel: false})))
          .then(() => hotelsService.uploadImgRoom(formDataForRoom).catch((err) => 
                        this.setState({errorMsg: err, errorDialogOpen: true, dialogAddHotel: false})))
          .then((msg) => this.setState({files: [], succesDialogOpen: true, successMsg : msg, dialogAddHotel: false}))
          .catch((err) => this.setState({errorMsg: err, errorDialogOpen: true, dialogAddHotel: false}))
    });
  };

//  Merge obj roomstypeObj with state obj (for faster myabe??:) )
// add new elem to array roomstype
    handleAddRoomToRoomsTypeObj = () => {   
      this.setState({
        roomstypeObj : {
          ...this.state.roomstypeObj,
          food : this.state.food,
          beds : this.state.beds,
          roomname : this.state.roomname,
          description : this.state.descriptionRoom,
          rules : this.state.rules,
          services: this.state.servicesroom,
          imagesroom : this.state.imagesroom
        }
      }, () => {
                this.setState({
                  roomstype :[
                    ...this.state.roomstype,
                    this.state.roomstypeObj
                  ],
                  dialogAddRoom : false,
                  imagesroom: [],
                  }, () => console.log(this.state.roomstype)) })
    }

    // for ROOM
    deleteRoomFromTypeOfRoom = id => event => {
      //roomstype
      const removeElement = (arr, i) => [...arr.slice(0, i), ...arr.slice(i+1)];
      this.setState(state => ({ roomstype: removeElement(state.roomstype, id) }));
    }

    handleChangeDescriptionRoom = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const descriptionRoom = state.descriptionRoom.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { descriptionRoom, }
        })
    };

    handleChangeRoomName = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const roomname = state.roomname.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { roomname, }
        })
    };

    handleChangeRoomRules = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const rules = state.rules.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { rules, }
        })
    };

    handleChangeRoomFood = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const food = state.food.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { food, }
        })
    };

    handleChangeRoomBeds = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const beds = state.beds.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { beds, }
        })
    };

    handleChangeFieldsRoom = name => event => {
      this.setState({roomstypeObj :{
        ...this.state.roomstypeObj,
        [name] : event.target.value
        }
      });
    };

    handleUploadImgForRoom = (event) => {
      event.preventDefault();
      const imagesroom = Array.from(event.target.files)
      this.setState({ imagesroom : imagesroom});
    };

    handleChangeServicesRoom = (serviceName, itemName) => event => {
      
      //state has nested arrays of objects. we changed selected true\false
      //1 map over service
      //2 map over items of service
      //3 change state of item of service
      //4 bec. setState  is async, we use callback for set state hotel.services
      this.setState({
        servicesroom : this.state.servicesroom.map((service, index) => {
          let items;
          if(service.name === serviceName) {
              items = service.items.map((item, index) => {
                  if(item.name === itemName) {
                      return {
                        ...item, selected: !item.selected
                      };
                    }
                  return item;
                }); 
              return {
                    ...service,
                    items : items,
                  };
            }
              return service;
          })
            }) 
    };

    //FOR HOTEL
    handleChangeFieldsModal = name => event => {
      this.setState({hotel :{
        ...this.state.hotel,
        [name] : event.target.value
        }
      });
    };

    handleChangeDescription = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const description = state.description.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { description, }
        })
    };

    handleChangeRegion = lang  => event => {
      let value = event.target.value;
      console.log(this.state.region);
      this.setState( state => {
        const region = state.region.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { region, }
        })
    };

    handleChangeCity = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const city = state.city.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { city, }
        })
    };

    handleChangeHotelName = lang  => event => {
      this.setState({hotelname :{
        ...this.state.hotelname,
        [lang] : event.target.value
        }
      });

      //if we use another shema for hotelname = [{obj}]

      // let value = event.target.value;
      // this.setState( state => {
      //   const hotelname = state.hotelname.map((elem) => { 
      //     if(elem.lang === lang) 
      //       return {...elem, value: value}
      //    else return elem
      // });
      //   return { hotelname, }
      //   })
    };

    handleChangeAccomod = name => event => {
      //change selected state in array of obj
      this.setState( state => {
        const typeOfAccommod = state.typeOfAccommod.map((accommod) => { 
          if(accommod.name === name) return {...accommod, selected: !accommod.selected}
         else return accommod
      });
        return { typeOfAccommod, }
        })
    };

    handleChangeServices = (serviceName, itemName) => event => {
      
      //state has nested arrays of objects. we changed selected true\false
      //1 map over service
      //2 map over items of service
      //3 change state of item of service
      //4 bec. setState  is async, we use callback for set state hotel.services
      this.setState({
        services : this.state.services.map((service, index) => {
          let items;
          if(service.name === serviceName) {
              items = service.items.map((item, index) => {
                  if(item.name === itemName) {
                      return {
                        ...item, selected: !item.selected
                      };
                    }
                  return item;
                }); 
              return {
                    ...service,
                    items : items,
                  };
            }
              return service;
          })
            }) 
    };

    handleUploadImg = (event) => {
      event.preventDefault();
      const files = Array.from(event.target.files)
      this.setState({ files : files});
    };


// MODAL HANDLE
    handleModalError = () => {
      this.setState({errorDialogOpen: !this.state.errorDialogOpen})
    };

    handleModalSuccess = () => {
      const { dispatch } = this.props;
      dispatch(changeStateHotel("created"));
      this.setState({succesDialogOpen: !this.state.succesDialogOpen})
    };

    handleModalAddHotel = () => {
      this.setState({dialogAddHotel: !this.state.dialogAddHotel})
    };
    handleModalAddRoom = () => {
      this.setState({dialogAddRoom: !this.state.dialogAddRoom})
    };

    handleChangeTabForHotel = (event, value) => {
      this.setState({ valueTabForHotel : value });
    };
    handleChangeTabForRoom = (event, value) => {
      this.setState({ valueTabForRoom : value });
    };
  
  render() {
    const { classes } = this.props;
    console.log(this.props.strings);
    return (
      <Paper className={classes.paper}>
        <Typography color="primary" className={classes.header} variant="h5" id="modal-title">Add Hotel</Typography>
        
        <Button color="primary" size="large" variant="contained" 
                className={classes.buttonAddHotel} onClick={this.handleModalAddHotel}>
            Add Hotel
        </Button>

        <MultiLangHotelInfo 
          value={this.state.valueTabForHotel} 
          handleChangeTab={this.handleChangeTabForHotel} 
          handleChangeHotelName={this.handleChangeHotelName}
          handleChangeDescription={this.handleChangeDescription}
          handleChangeCity={this.handleChangeCity}
          handleChangeRegion={this.handleChangeRegion}
          city={this.state.city}
          region={this.state.region}
          hotelname={this.state.hotelname}
          description={this.state.description}
          />
          
        <MainInfoHotel
          handleChangeFieldsModal = {this.handleChangeFieldsModal}
          hotel = {this.state.hotel}
          />
        <TypeOfAccommodation 
            handleChange={this.handleChangeAccomod}
            typeOfAccommod={this.state.typeOfAccommod}
          />
        <UploadImagesForHotel handleUploadImg={this.handleUploadImg} files={this.state.files} />

        <Grid container spacing={8}>
          <Services 
            handleChangeOfService={this.handleChangeServices} 
            services={this.state.services} />
        </Grid>

        <Divider variant="fullWidth" /> 
          <Typography color="primary" variant="h6"> Rooms section </Typography>
       
        <Divider variant="fullWidth" /> 
          <ShowRooms 
            roomstype={this.state.roomstype} 
            deleteRoomFromTypeOfRoom={this.deleteRoomFromTypeOfRoom} 
          />

        <Divider variant="fullWidth" /> 

        <Button color="primary" size="large" variant="contained" className={classes.button}
                onClick={this.handleModalAddRoom}> 
          Add New Room
        </Button>

        <MultiLangRoomInfo 
          value={this.state.valueTabForRoom} 
          handleChangeTab={this.handleChangeTabForRoom} 
          handleChangeDescriptionRoom={this.handleChangeDescriptionRoom}
          handleChangeRoomName={this.handleChangeRoomName}
          handleChangeRoomRules={this.handleChangeRoomRules}
          handleChangeRoomFood={this.handleChangeRoomFood}
          handleChangeRoomBeds={this.handleChangeRoomBeds}
          roomname={this.state.roomname}
          description={this.state.descriptionRoom}
          rules={this.state.rules}
          food={this.state.food}
          beds={this.state.beds}
          />
        <MainInfoRoom handleChangeFieldsRoom={this.handleChangeFieldsRoom}/>

        <UploadImagesForRoom 
          handleUploadImg={this.handleUploadImgForRoom} 
          imagesroom={this.state.imagesroom} 
          />

        <Grid container spacing={8}>
          <ServicesRoom 
            handleChangeOfService={this.handleChangeServicesRoom} 
            services={this.state.servicesroom} 
            />
        </Grid>


        <ModalAddRoom 
          open={this.state.dialogAddRoom} 
          handleClose={this.handleModalAddRoom} 
          handleAddRoom={this.handleAddRoomToRoomsTypeObj} 
        />
        <ModalAddHotel 
          open={this.state.dialogAddHotel} 
          handleClose={this.handleModalAddHotel} 
          handleCreateHotel={this.handleCreateNewHotel}
        />
        <ModalError 
          open={this.state.errorDialogOpen} 
          error={this.state.errorMsg} 
          handleClose={this.handleModalError}
        />
        <ModalSuccess 
          open={this.state.succesDialogOpen}
          success={this.state.successMsg} 
          handleClose={this.handleModalSuccess}
        />
    </Paper>
       )
     }
  }

AddHotel.propTypes = {
    classes: PropTypes.object.isRequired,
};

  
const AddHotelCompose = compose(
    withStyles(styles),
    translate('Services'),
  )(AddHotel);

export {AddHotelCompose as AddHotel};

