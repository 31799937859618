export const regions_en = [
    {
        value : 'Kiev Region',
        label : 'Kiev Region'
    },
    {
        value : 'Cherkasy Region',
        label : 'Cherkasy Region'
    },
    {
        value : 'Chernihiv Region',
        label : 'Chernihiv Region'
    },
    {
        value : 'Chernivtsi Region',
        label : 'Chernivtsi Region'
    },
    {
        value : 'Dnipropetrovs\'k Region',
        label : 'Dnipropetrovs\'k Region'
    },
    {
        value : 'Donets\'k Region',
        label : 'Donets\'k Region'
    },
    {
        value : 'Ivano-Frankivs\'k Region',
        label : 'Ivano-Frankivs\'k Region'
    },
    {
        value : 'Kharkiv Region',
        label : 'Kharkiv Region'
    },
    {
        value : 'Kherson Region',
        label : 'Kherson Region'
    },
    {
        value : 'Khmelnytsky Region',
        label : 'Khmelnytsky Region'
    },
    {
        value : 'Kirovograd Region',
        label : 'Kirovograd Region'
    },
    {
        value : 'Luhans\'k Region',
        label : 'Luhans\'k Region'
    },
    {
        value : 'Lviv Region',
        label : 'Lviv Region'
    },
    {
        value : 'Mykolayiv Region',
        label : 'Mykolayiv Region'
    },
    {
        value : 'Odesa Region',
        label : 'Odesa Region'
    },
    {
        value : 'Poltava Region',
        label : 'Poltava Region'
    },
    {
        value : 'Rivne Region',
        label : 'Rivne Region'
    },

    {
        value : 'Sumy Region',
        label : 'Sumy Region'
    },
    {
        value : 'Ternopil Region',
        label : 'Ternopil Region'
    },
    {
        value : 'Vinnytsya Region',
        label : 'Vinnytsya Region'
    },
    {
        value : 'Volyn Region',
        label : 'Volyn Region'
    },
    {
        value : 'Zakarpattya Region',
        label : 'Zakarpattya Region'
    },
    {
        value : 'Zaporizhzhya Region',
        label : 'Zaporizhzhya Region'
    },
    {
        value : 'Zhytomyr Region',
        label : 'Zhytomyr Region'
    },
];

export const regions_uk = [
    {
        value : 'Київська область',
        label : 'Київська область'
    },
    {
        value : 'Вінницька область',
        label : 'Вінницька область'
    },
    {
        value : 'Волинська область',
        label : 'Волинська область'
    },
    {
        value : 'Дніпропетровська область',
        label : 'Дніпропетровська область'
    },
    {
        value : 'Донецька область',
        label : 'Донецька область'
    },
    {
        value : 'Житомирська область',
        label : 'Житомирська область'
    },
    {
        value : 'Закарпатська область',
        label : 'Закарпатська область'
    },
    {
        value : 'Запорізька область',
        label : 'Запорізька область'
    },
    {
        value : 'Івано-Франківська область',
        label : 'Івано-Франківська область'
    },
    {
        value : 'Кіровоградська область',
        label : 'Кіровоградська область'
    },
    {
        value : 'Луганська область',
        label : 'Луганська область'
    },
    {
        value : 'Львівська область',
        label : 'Львівська область'
    },
    {
        value : 'Миколаївська область',
        label : 'Миколаївська область'
    },
    {
        value : 'Одеська область',
        label : 'Одеська область'
    },
    {
        value : 'Полтавська область',
        label : 'Полтавська область'
    },
    {
        value : 'Рівненська область',
        label : 'Рівненська область'
    },
    {
        value : 'Сумська область',
        label : 'Сумська область'
    },
    {
        value : 'Тернопільська область',
        label : 'Тернопільська область'
    },
    {
        value : 'Харківська область',
        label : 'Харківська область'
    },
    {
        value : 'Херсонська область',
        label : 'Херсонська область'
    },
    {
        value : 'Хмельницька область',
        label : 'Хмельницька область'
    },
    {
        value : 'Черкаська область',
        label : 'Черкаська область'
    },
    {
        value : 'Чернівецька область',
        label : 'Чернівецька область'
    },
    {
        value : 'Чернігівська область',
        label : 'Чернігівська область'
    },
];

export const regions_ru = [
    {
        value : 'Киевская область',
        label : 'Киевская область'
    },
    {
        value : 'Винницкая область',
        label : 'Винницкая область'
    },
    {
        value : 'Волынская область',
        label : 'Волынская область'
    },
    {
        value : 'Днепропетровская область',
        label : 'Днепропетровская область'
    },
    {
        value : 'Донецкая область',
        label : 'Донецкая область'
    },
    {
        value : 'Житомирская область',
        label : 'Житомирская область'
    },
    {
        value : 'Закарпатская область',
        label : 'Закарпатская область'
    },
    {
        value : 'Запорожская область',
        label : 'Запорожская область'
    },
    {
        value : 'Ивано-Франковская область',
        label : 'Ивано-Франковская область'
    },
    {
        value : 'Кировоградская область',
        label : 'Кировоградская область'
    },
    {
        value : 'Луганская область',
        label : 'Луганская область'
    },
    {
        value : 'Львовская область',
        label : 'Львовская область'
    },
    {
        value : 'Николаевская область',
        label : 'Николаевская область'
    },
    {
        value : 'Одесская область',
        label : 'Одесская область'
    },
    {
        value : 'Полтавская область',
        label : 'Полтавская область'
    },
    {
        value : 'Ровненская область',
        label : 'Ровненская область'
    },
    {
        value : 'Сумская область',
        label : 'Сумская область'
    },
    {
        value : 'Тернопольская область',
        label : 'Тернопольская область'
    },
    {
        value : 'Харьковская область',
        label : 'Харьковская область'
    },
    {
        value : 'Херсонская область',
        label : 'Херсонская область'
    },
    {
        value : 'Хмельницкая область',
        label : 'Хмельницкая область'
    },
    {
        value : 'Черкасская область',
        label : 'Черкасская область'
    },
    {
        value : 'Черниговская область',
        label : 'Черниговская область'
    },
    {
        value : 'Черновицкая область',
        label : 'Черновицкая область'
    },
];