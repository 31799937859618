import { langConstans } from '../_constants';

export function language(state='uk' , action) {
    switch (action.type) {
        case langConstans.Ukraine:
          return 'uk';
        case langConstans.Russia:
          return 'ru';
        case langConstans.English:
            return 'en';
        default:
          return state;
      }
} 