import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { PrivateRoute } from '../_components';
import { history } from '../_helpers';
import { alertActions } from '../_actions';

import './App.css';
//import SignIn from '../LoginPage/SignIn';
import { SignIn } from '../LoginPage';
import {Dashboard} from '../Dashboard/Dashboard';

class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
        // clear alert on location change
        dispatch(alertActions.clear());
    });
  }


  render() {
      return (
           <Router history={history}>
              <div>
              <PrivateRoute exact path="/" component={Dashboard} />
              <Route path="/login" component={SignIn} />
              </div>
          </Router>
      );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
      alert
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 
