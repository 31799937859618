import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  textField : {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  paper: {
    paddingTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginTop : theme.spacing.unit,
  }
});


function HotelMainInfo(props) {
  const { classes, } = props;
  return (
    <Paper className={classes.paper}>
      <Typography variant="title" gutterBottom>Main Info</Typography>
      <TextField
          required
          id="latitude"
          label="Latitude"
          variant="outlined"
          type="number"
          value={props.hotel.latitude}
          InputLabelProps={{
              shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          helperText="Correct: 41.40338"
          onChange={props.handleChangeFieldsModal('latitude')}
        />
        <TextField
          required
          id="longitude"
          label="Longitude"
          variant="outlined"
          type="number"
          value={props.hotel.longitude}
          InputLabelProps={{
              shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          helperText="Correct: 41.40338"
          onChange={props.handleChangeFieldsModal('longitude')}
        />
        <TextField
          id="phone"
          label="Phone numbers"
          type="phone"
          name="phone"
          rows = {2}
          rowsMax = {3}
          multiline = {true}
          autoComplete="phone"
          value={props.hotel.phone}
          className={classes.textField}
          onChange={props.handleChangeFieldsModal('phone')}
          margin="normal"
          variant="outlined"
          helperText="phones separated by commas"
        />
        <TextField
          id="site"
          label="Website address"
          type="site"
          name="site"
          autoComplete="site"
          value={props.hotel.site}
          className={classes.textField}
          onChange={props.handleChangeFieldsModal('site')}
          margin="normal"
          variant="outlined"
          helperText="example: www.mysite.com.ua"
        />
    </Paper>
  );
}

HotelMainInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HotelMainInfo);