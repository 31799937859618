import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ImageIcon from '@material-ui/icons/Image';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  input: {
    display: 'none',
    },
  root: {
    padding: theme.spacing.unit * 2,
  },
  header : {
    margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
  },
  body: {
    margin: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  checkbox : {
    padding : 4,
  },
  legend : {
    marginBottom: 20,
  }
});

function UploadImagesForHotel(props) {
    const { classes, files} = props;
    return (
     <>
        <div className= {classes.root}>
            <div className = {classes.header}>
                <Typography color="default" variant="title">Upload images for Hotel Gallery</Typography>
            </div>
            <Divider variant="middle" />
            <div className = {classes.body}>
                    <input
                        className={classes.input}
                        id="imghotel"
                        type="file"
                        onChange={props.handleUploadImg}
                        multiple
                    />
                    <label htmlFor="imghotel">
                        <Button variant="contained" component="span" className={classes.button}>
                        Upload Images
                        </Button>
                    </label>
                        {files.map((file, id) => (
                            <List dense={true} key={id}>
                                <ListItem key={id}>
                                    <ListItemIcon>
                                        <ImageIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={file.name}
                                        secondary={'Size is ' + file.size + ' Kb'}
                                    />
                                </ListItem>
                            </List>    
                        ))}
                </div>
            <Divider variant="middle" />
        </div>
    </>
    );
  }

  UploadImagesForHotel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadImagesForHotel);