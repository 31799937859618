import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  textField : {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit*2,
  },
  paper: {
    paddingTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginTop : theme.spacing.unit,
  }
});


function AddPlaceId(props) {
  const { classes,} = props;
  return (
    <Paper className={classes.paper}>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6" color="secondary">
                    We must add place_id before load data from GOOGLE API!
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button
                    onClick={props.handleClickUpdateHotelButton}
                    color="primary" 
                    size="large" 
                    variant="contained" 
                    className={classes.button}
                >
                    Update Hotel
                </Button>
                <Button
                    onClick={props.handleClickCloseButton}
                    color="secondary" 
                    size="large" 
                    variant="contained" 
                    className={classes.button}
                >
                    Close
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Button
                    onClick={props.handleClickTryFindPlaceIdAutomaticallyButton('phone')}
                    color="primary" 
                    size="large" 
                    variant="contained" 
                    className={classes.button}
                >
                    Try find place_id by PHONE
                </Button>
                <TextField
                    id="phone"
                    label="enter phone of place to try find place_id"
                    type="phone"
                    name="phone"
                    autoComplete="phone"
                    className={classes.textField}
                    onChange={props.handleChangeFieldsFindPlaceId('phone')}
                    margin="normal"
                    variant="outlined"
                    helperText="example: +3804455544422"
                    />
            </Grid>
            <Grid item xs={12}>
                <Button
                    onClick={props.handleClickTryFindPlaceIdAutomaticallyButton('text')}
                    color="primary" 
                    size="large" 
                    variant="contained" 
                    className={classes.button}
                >
                    Try find place_id by TEXT
                </Button>
                <TextField
                    id="text"
                    label="enter some text about place to try find place_id"
                    type="text"
                    name="text"
                    autoComplete="text"
                    className={classes.textField}
                    onChange={props.handleChangeFieldsFindPlaceId('text')}
                    margin="normal"
                    variant="outlined"
                    helperText="example: Lisotel or База отдыха за двумя зайцами"
                    />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="place_id"
                    label="You can enter place_id manually"
                    type="place_id"
                    name="place_id"
                    autoComplete="place_id"
                    className={classes.textField}
                    onChange={props.handleChangeFieldsModal('place_id')}
                    margin="normal"
                    variant="outlined"
                    helperText="example: ChIJQVfF8cdz00ARm1I0_X9BQRc"
                    />
            </Grid>
        </Grid>
    </Paper>
  );
}

AddPlaceId.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddPlaceId);