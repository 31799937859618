export const config = { 
    //dev
   // apiUrl: 'http://localhost:3001',
   // urlImgHotel : 'http://localhost:3001/hotels/',
   // urlImgRoom : 'http://localhost:3001/rooms/',
    //prod
    apiUrl: 'http://api.zamistom.kiev.ua',
    urlImgHotel : 'http://api.zamistom.kiev.ua/hotels/',
    urlImgRoom : 'http://api.zamistom.kiev.ua/rooms/',

};
