import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import {hotelsService} from '../_services';
import {UpdateHotel} from '../UpdateHotel/UpdateHotel';
import TableListOfHotels from './TableListOfHotels';
import DeleteDialog from './DeleteDialog';
import SearchHotel from './SearchHotel';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  hide : {
    visibility: 'hidden',
  },
  button: {
    margin: theme.spacing.unit,
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 35,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  paperBlack: {
    backgroundColor: "black",
    padding: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  }, 
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

class Hotels extends Component {

  componentDidMount() {
    hotelsService.getAll()
      .then(data => this.setState({hotels: data, isLoaded: true}))
      .catch(err => console.log("get all errror " , err));
 }

  constructor(props) {
    super(props);
    this.state = {
      hotel : {
        hotelname : {
          en:'',
          uk:'',
          ru:'',
        },
        description : [
          { lang:'en', value: ''},
          { lang:'uk', value: ''},
          { lang:'ru', value: ''},
        ] ,
        city : [
          { lang:'en', value: ''},
          { lang:'uk', value: ''},
          { lang:'ru', value: ''},
        ] ,
        region : [
          { lang:'en', value: ''},
          { lang:'uk', value: ''},
          { lang:'ru', value: ''},
        ] ,
        roomstype : [],
        latitude: '12',
        longitude: '12',
        distance_from_city: '12',
        typeOfAccommod : [
          {name: 'Cottage' , selected: false}, {name: 'Bungalow' , selected: false},
          {name: 'Main Building', selected: false}, {name: 'Villa', selected: false},
          {name: 'Tent' , selected: false}],
          services: [
            {name: 'Activities' ,
              items :[
                {name:'Beach', selected: false},
                {name:'Boat/catamaran rental', selected: false},
                {name:'GyroScooter rental', selected: false},
                {name:'Tennis court', selected: false},
                {name:'Kids\' club', selected: false},
                {name:'Playground', selected: false},
                {name:'Horseback riding ', selected: false},
                {name:'Cycling routes', selected: false},
                {name:'Bicycle rental', selected: false},
                {name:'Darts', selected: false},
                {name:'Ping-pong', selected: false},
                {name:'Pool table', selected: false},
                {name:'Fishing', selected: false},
                {name:'Zoo', selected: false},
                {name:'Paintball', selected: false},
                {name:'Rope park', selected: false},
                {name:'Trampoline', selected: false},],
            },
            {name: 'Pool & SPA' ,
              items :[
                {name:'Solarium', selected: false},
                {name:'Massage', selected: false},
                {name:'SPA area', selected: false},
                {name:'Beauty parlor', selected: false},
                {name:'Bath: russian/finnish', selected: false},
                {name:'Sauna', selected: false},
                {name:'Pool', selected: false},
                {name:'Indoor pool', selected: false},
                {name:'Children\'s pool', selected: false},
                {name:'Heated swimming pool', selected: false}],
            },
            {name: 'Territory' ,
              items :[
                {name:'Forest', selected: false},
                {name:'Garden', selected: false},
                {name:'Terrace', selected: false},
                {name:'BBQ facilities', selected: false},
                {name:'Skewers for rent', selected: false},
                {name:'Altanka rental', selected: false},
                {name:'Altanka for free', selected: false},
                {name:'Benches', selected: false},
                {name:'Lawn', selected: false},
                {name:'Apiary', selected: false},
                {name:'Food shop', selected: false},
                {name:'Gift shop', selected: false},
                {name:'Pond', selected: false},
                {name:'Lake', selected: false},
                {name:'River', selected: false},],
            }, 
            {name: 'Food & Drink' ,
              items :[
                {name:'Restaurant', selected: false},
                {name:'Cafe', selected: false}, 
                {name:'Bar', selected: false},
                {name:'Hookah', selected: false},
                {name:'Diet food', selected: false},
                {name:'Alcoholic drinks', selected: false},
                {name:'Cooking on the grill', selected: false},
                {name:'Food from the oven', selected: false},
                {name:'Kids\' meals', selected: false},
                {name:'Self cooking', selected: false},
                {name:'Food delivery to the room', selected: false},],
            },
            {name: 'General' ,
              items :[
                {name:'Medical staff', selected: false},
                {name:'Smoking area', selected: false},
                {name:'Tour agency', selected: false},
                {name:'Express check-in/out', selected: false},
                {name:'Private check-in/out', selected: false}, 
                {name:'Terminal', selected: false},
                {name:'Payment by credit card', selected: false},
                {name:'24-hour front desk', selected: false},
                {name:'Guarded territory', selected: false},
                {name:'Conference hall', selected: false},
                {name:'Organization of events', selected: false}, 
                {name:'Parking charge', selected: false},
                {name:'Parking free', selected: false},
                {name:'Accommodation with animals', selected: false},
                {name:'Children\'s beds in the room', selected: false}, 
                {name:'Room cleaning', selected: false},
                {name:'Nanny', selected: false},],
            },
            {name: 'Languages Spoken' ,
              items :[
                {name:'English', selected: false},
                {name:'Russian', selected: false},
                {name:'Ukrainian', selected: false},],
            },
            {name: 'WiFi & Internet' ,
              items :[
                {name: 'In the territory', selected: false},
                {name: 'In the rooms', selected: false},
                {name: 'In the restaurant', selected: false},
                {name: 'In the main building', selected: false},],
            },
          ],
      },
      hotels : [],
      submitted: false,
      error: '',
      openError: false,
      openUpdate : false,
      openDialog: false,
      openListHotels: true,
      isLoaded: false,
      hotelname : {
        en:'',
        uk:'',
        ru:'',
      },
      hotelnameDelete: {
        en:'',
        uk:'',
        ru:'',
      },
      rowsPerPage: 10,
      page: 0,
  };
  }
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleDeleteHotel = (hotelname) => event => {
    hotelsService.deleteHotel(hotelname)
      .then(res => {if(res) hotelsService.getAll().then(data => this.setState({hotels: data, openDialog: false}))})
      .catch(err => this.setState({error: err, openError : true,}));
  }
  
  handleCloseUpdate = () => {
    this.setState({ openUpdate: false, showlistHotels: true, hotelname: {en:'',uk:'',ru:'',} });
  };

  handleOpenUpdate = (hotelname) => event => {
    this.setState({ openUpdate: true, openListHotels: false, hotelname: hotelname });
  };

  handleClickOpenDialog= (hotelname) => event => {
    this.setState({ openDialog: true, hotelnameDelete: hotelname });
  };

  handleCloseDialog= () => {
    this.setState({ openDialog: false, hotelnameDelete: {en:'',uk:'',ru:'',}, });
  };

  //search field -> find hotel by id
  handleOnChangeHotelName = (hotel) => {
    hotelsService.findById(hotel.id)
        .then(hotel => this.setState({hotelname : hotel.hotelname, openUpdate: true, openListHotels: false}))
        .catch(err => this.setState({errorMsg : err,errorDialogOpen: true, }));
    }

  render() {
  const { classes } = this.props;
  let hotelnames = [];
  console.log(this.state.hotel);
  //prepare array with suggestions hotelname, id
  this.state.hotels.forEach(hotel => { 
          hotelnames.push({value: hotel.hotelname.en, id: hotel._id});
          hotelnames.push({value: hotel.hotelname.uk, id: hotel._id});
      })
  return (
    <>
      <Grid item xs={12}>
        
        {this.state.openListHotels &&
            <Paper className={classes.root}>
              {this.state.isLoaded ?
                <>
                  <SearchHotel
                    hotelnames ={hotelnames}
                    handleOnChangeHotelName={this.handleOnChangeHotelName}
                    /> 
                  <TableListOfHotels
                    hotels={this.state.hotels}
                    openDialog={this.state.openDialog}
                    handleClickOpenDialog={this.handleClickOpenDialog}
                    handleCloseDialog={this.handleCloseDialog}
                    handleDeleteHotel={this.handleDeleteHotel}
                    handleOpenUpdate={this.handleOpenUpdate}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleChangePage={this.handleChangePage}
                    />
                  </>: <CircularProgress className={classes.progress} />}
        </Paper>}

        {this.state.openUpdate && 
          <Paper className={classes.root}>
            <UpdateHotel hotelname={this.state.hotelname}/>
          </Paper>}
     </Grid>
     <DeleteDialog 
        openDialog={this.state.openDialog} 
        hotelname={this.state.hotelnameDelete}
        handleDeleteHotel={this.handleDeleteHotel}
        handleCloseDialog={this.handleCloseDialog}
        />
   </>
    );
}}

Hotels.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Hotels);
