import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import MainListItems from './listItems';
import Hotels from '../ListHotels/Hotels';
import MyAppBar from './MyAppBar';
import {AddHotel} from '../AddHotel/AddHotel';
import Users from '../Users/Users';
import GoogleApi from '../GoogleApi/GoogleApi';

import {changeStateHotel} from '../_actions'

const drawerWidth = 180;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
});

class Dashboard extends React.Component {
 
  constructor(props) {
    super(props);

    this.state = {
        user: {},
        users: [],
        open: false,
        showHotels: false,
        showAddHotel : false,
        showUsers: false,
        showDashboard: true,
        showGoogleApi: false,
        hotel : {
          hotelname : '',
          desc : '',
          rooms : [
            {name: [ { lang: 'en', value : '' } ], desc: ''},
            {name: [ { lang: 'en', value : '' } ], desc: ''},
          ]
        }
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleClickListItem = (e, listItemName) => {

    const { dispatch } = this.props;
    dispatch(changeStateHotel("nostate"));

    switch(listItemName) {
      case 'Dashboard' : 
          this.setState({showAddHotel: false, showHotels: false, 
            showUsers: false, showDashboard: true, showGoogleApi:false,});
          break;
      case 'showHotels' :
          this.setState({showAddHotel: false, showHotels: true, 
            showUsers: false, showDashboard: false, showGoogleApi:false,});
          break;
      case 'showAddHotel' :
          this.setState({showAddHotel: true, showHotels: false, 
            showUsers: false, showDashboard: false, showGoogleApi:false,});
          break;
      case 'showUsers' :
          this.setState({showAddHotel: false, showHotels: false, 
            showUsers: true, showDashboard: false, showGoogleApi:false,});
          break;
      case 'showGoogleApi' :
          this.setState({showAddHotel: false, showHotels: false, 
            showUsers: false, showDashboard: false, showGoogleApi:true,});
          break;
      default: 
          this.setState({showAddHotel: false, showHotels: false, 
            showUsers: false, showDashboard: true, showGoogleApi:false,});
    }
  }

  render() {
    const { classes, hotelState } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        
        <MyAppBar open = {this.state.open} handleDrawerOpen = {this.handleDrawerOpen} />
        
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List><MainListItems onClick={(e,listItemName) => this.handleClickListItem(e, listItemName)}/></List>
          <Divider />
        </Drawer>
      
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {this.state.showDashboard && 
            <>
              Nothing added yet, myabe wil i show some analytics...
            </>}
          {this.state.showHotels&&hotelState!=='updated' && <Hotels />}
          {this.state.showAddHotel&&hotelState!=='created' && <AddHotel />}
          {this.state.showUsers && <Users />}
          {this.state.showGoogleApi && <GoogleApi />}
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { hotelState, language } = state;
  return {
    hotelState,
    language
  };
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const DashboardCompose = compose(
  withStyles(styles),
  connect(mapStateToProps),
)(Dashboard);

export {DashboardCompose as Dashboard};