import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import MainInfoRoom from '../../UpdateHotel/EditRoom/MainInfoRoom';
import MultiLangRoomInfo from '../../UpdateHotel/EditRoom/EditMultiLangRoomInfo';
import {ServicesRoom} from '../../UpdateHotel/Rooms/ServicesRoom';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    dialotTitle : {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit * 2,
      //background : 'red',
    },
    dialogContent : {
      padding: theme.spacing.unit * 2,
    }
  });
function ModalEditRoom(props) {

  const { classes, fullScreen, editRoomId } = props;
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.dialotTitle} id="responsive-dialog-title">{'Attention!'}</DialogTitle>
        {props.editRoomId!=='' ?
            <DialogContent>
                    <MultiLangRoomInfo
                      room={props.roomstype[editRoomId]}
                      value={props.value}
                      id={editRoomId}
                      handleChangeTab={props.handleChangeTab} 
                      handleEditDescriptionRoom={props.handleEditDescriptionRoom}
                      handleEditRoomName={props.handleEditRoomName}
                      handleEditRoomRules={props.handleEditRoomRules}
                      handleEditRoomFood={props.handleEditRoomFood}
                      handleEditRoomBeds={props.handleEditRoomBeds}
                    />
                    <MainInfoRoom
                      id={editRoomId}
                      room={props.roomstype[editRoomId]}
                      handleEditFieldsRoom={props.handleEditFieldsRoom}
                    />
                    <Grid container spacing={8}>
                      <ServicesRoom 
                        handleChangeOfService={props.handleEditServicesRoom} 
                        services={props.roomstype[editRoomId].services}
                        />
                     </Grid>
            </DialogContent> :
            <DialogContent>
                <DialogContentText className={classes.dialogContent}>
                    We don't have any room
                </DialogContentText>
            </DialogContent>
        }
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Exit
          </Button>
          <Button onClick={props.handleClose} color="primary">
            Change Room
          </Button>
        </DialogActions>
    </Dialog>
      )
  }

ModalEditRoom.propTypes = {
    classes: PropTypes.object.isRequired,
};
 
export default withStyles(styles)(ModalEditRoom);

// {props.roomstype[props.editRoomId].roomname.map((name, id) => (
//     <div key={id}>{name.lang} : {name.value}<br/></div>
// ))}