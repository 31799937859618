import { authHeader } from '../_helpers';
import {config} from '../_config';


export const userService = {
    login,
    logout,
    getAll,
    register,
    deleteUser
};

function register(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({username, password})
    };
    return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
}

function deleteUser(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({userId})
    };

    return fetch(`${config.apiUrl}/users/delete`, requestOptions).then(handleResponse);
}

function login(username, password) {
    const requestOptions = {
        method : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({username, password}),
    };
        return fetch((`${config.apiUrl}/users/authenticate`), requestOptions)
        .then(handleResponse)
        .then(user => {
            console.log("USER fetch login=" ,user);
              // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                user.authdata = window.btoa(username + ':' + password);
                localStorage.setItem('user', JSON.stringify(user));
                document.cookie = "token=" + user.token;
            } else {
                return ('Username or password is incorrect');
            }
            return user;
        });
        //.catch(err => console.log("CCCC", err));

}

function logout() {
    //console.log(window.sessions.setWindowValue());
    localStorage.removeItem('user');
    document.cookie = "token = ; expires= Thu, 21 Aug 2014 20:00:00 UTC"
}

function getAll(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    console.log("start getAll()");
    return fetch(`${config.apiUrl}/users/all`, requestOptions).then(handleResponse);
}

function handleResponse(response){
    return response.text().then(text => {
        console.log("text handleResponse", text);
        const data = text && JSON.parse(text);
        console.log("data handleResponse" , data);
        if(!response.ok) {
            console.log("response not OK", response.status);
            if(response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            console.log("fffffffffffffffffff" ,error);
            return Promise.reject(error);
        }
        return data;
    });
}