import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function DeleteDialog(props) {
    const {hotelname, openDialog, handleCloseDialog, handleDeleteHotel} = props;
    return (
      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete hotel "}
                {Object.keys(hotelname).map((key,id) => (
                          <div key={id}>Language {key} : {hotelname[key]}<br/></div>
                      ))}
                      ?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              I you sure that you want delete hotel?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Disagree
            </Button>
              <Button onClick={handleDeleteHotel(hotelname)} color="primary" autoFocus>
                Delete Hotel
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}

export default DeleteDialog;