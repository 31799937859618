export default {
    RoomsType : {
       roomsTypeHeader : 'Доступні номери',
       name :['Ванна кімната','Зручності у номері'],
       services : [
            ['Душ','Ванна','Біде','Гігієнічний душ','Туалет','Туалетні приналежності','Рушники',
            'Халат','Фен','Одноразові щітки','Джакузі','Пральна машина',],
            ['Міні-бар','DVD-плеєр','Радіатори опалення','Капці','Супутникові канали','Інтернет',
            'Телевізор з пласким екраном','Паркетна підлога','Постільна білизна', 'Москітна сітка',
            'Шафа/гардероб','Письмовий/журнальний столик','Кондиціонер','Тераса','Балкон',
            'Килимове покриття','Вікна в підлогу','Нерозкладний диван','Крісла','Електричний чайник',
            'Мікрохвильова піч','Холодильник','Плита','Столові прилади',],
        ],
    },

    Services : {
        name: ['Спорт і відпочинок','Басейн & SPA','Територія','Їжа і напої', 'Загальні',
            'Мова спілкування','WiFi & Інтернет',],
         services : [
            ['Пляж','Прокат човнів/катамаранів','Прокат гіроскутеров','Тенісний корт','Дитячий клуб',
            'Дитячий майданчик','Верхова їзда','Велосипедні маршрути','Прокат велосипедів','Дартс',
            'Настільний теніс','Більярд','Риболовля','Зоопарк','Пейнтбол','Мотузковий парк','Батут',],
            ['Солярій','Масаж','СПА зона','Косметичний кабінет','Лазня: російська/фінська','Сауна',
            'Басейн','Критий басейн','Дитячий басейн','Басейн з підігрівом',],
            ['Ліс','Сад','Тераса','Барбекю','Оренда шампурів/мангала','Альтанки платно',
            'Альтанки безкоштовно','Лавочки','Газон','Пасіка','Магазин продуктовий','Магазин сувенірний',
            'Ставок','Озеро','Річка',],
            ['Ресторан','Кафе','Бар','Кальян','Дієтичне харчування','Алкогольні напої','Приготування на мангалі',
            'Їжа з печі','Дитяче меню','Самостійне приготування','Доставка їжі в номер',],
            ['Медичний персонал','Місця для куріння','Екскурсійне бюро','Експрес реєстрація заїзду/виїзду',
            'Індівідуальна реєстрація','Термінал','Оплата банківською картою','Цілодобова стійка реєстрації',
            'Охорона території','Конференц зал','Організація урочистостей','Парковка платна',
            'Парковка безкоштовна','Розміщення з тваринами','Дитячі ліжка в номер','Прибирання номеру','Няня',],
            ['Англійська', 'Російська', 'Українська'],
            ['На території','У номерах','В ресторані','В головній будівлі',],
        ]
    },
    
    ListHotels : {
        title: 'Не тільки готелі ... Відкрийте для себе ідеальний комфорт будинків і апартаментів',
        distanceFromKiev : 'Відстань від Києва : ',
        typeOfAccommodationText : 'Тип проживання : ',
        typeOfAccommodation: ['Котедж', 'Бунгало', 'Головна будівля', 'Вілла', 'Намет'],
        foodAndDrinkText : 'Їжа та напої : ',
        foodAndDrink : ['Ресторан','Кафе','Бар','Кальян','Дієтичне харчування','Алкогольні напої','Приготування на мангалі',
        'Їжа з печі','Дитяче меню','Самостійне приготування','Доставка їжі в номер',],
        activitiesText : 'Розваги: ',
        activities:  ['Пляж','Прокат човнів/катамаранів','Прокат гіроскутеров','Тенісний корт','Дитячий клуб',
                'Дитячий майданчик','Верхова їзда','Велосипедні маршрути','Прокат велосипедів','Дартс',
                'Настільний теніс','Більярд','Риболовля','Зоопарк','Пейнтбол','Мотузковий парк','Батут',],
        descriptionTitle : 'Про комплекс',
    },
  };

  //             ['Папір туалетний', 'Білизна', 'Рушники', 'Біде', 'Тапочки', 'Власна ванна кімната',
//              'Безкоштовні туалетні приналежності', 'Халат', 'Фен', 'Душ'],

//             ['Пляж', 'Спорядження для тенісу', 'Вечірня програма', 'Дитячий клуб',
//              'Анімаційний персонал', 'Верхова їзда Оплачується окремо', 'Велоспорт', 'Маршрути для піших прогулянок',
//              'Бібліотека', 'Прокат велосипедів (оплачується окремо)', 'Дартс', 'Настільний теніс',
//              'Більярд', 'Дитячий ігровий майданчик', 'Ігрова кімната', 'Рибна ловля',],

//             ['Трансфер від / до аеропорту (сплачується окремо)', 'Трансфер (оплачується окремо)',
//              'Місця для куріння', 'Гіпоалергенний номер', 'Москітна сітка', 'Дерев\'яний або паркетна підлога',
//              'Опалення', 'Упаковані ланчі', 'Церква / храм', 'Звуконепроникні номера', 'Сувенірний магазин',
//              'Люкс для молодят', 'VIP-зручності в номерах', 'Сімейні номери', 'Номери для некурящих',
//              'Доставка їжі та напоїв в номер',],

//             ['Солярій', 'Приватна пляжна зона', 'Барбекю', 'Тераса', 'Сад',],

//             ['Вино / Шампанське', 'Дитяче харчування', 'Спеціальне дієтичне харчування (на вимогу)', 'Сніданок в номері',
//              'Бар', 'Ресторан', 'Міні-бар'],

//             ['Приватна реєстрація в\'їзду / виїзду', 'Туристичне бюро', 'Експрес-реєстрація заїзду / виїзду', 
//             'Цілодобова стійка реєстрації',],

//             ['Англійська', 'Російська', 'Українська'],

//             ['Безкоштовно! У номерах готелю надається безкоштовний Wi-Fi. ',
//                 'Wi-Fi не доступний в номерах готелю.', 'Wi-Fi доступний в номерах готелю. За додаткову оплату! '],

//             ['Безкоштовна громадська автостоянка доступна на території готелю (необхідне бронювання).',
//                  "Стоянка під охороною"],

//             ['Догляд за дітьми', 'Дитячий клуб'],   