import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  textField : {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  paper: {
    paddingTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginTop : theme.spacing.unit,
  }
});


function Raiting(props) {
  const { classes, } = props;
  return (
    <Paper className={classes.paper}>
      <Typography variant="title" gutterBottom>Raiting</Typography>
      <TextField
          required
          id="rating"
          label="Rating"
          variant="outlined"
          type="number"
          value={props.hotel.rating}
          InputLabelProps={{
              shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          helperText="Correct: 4.7"
          onChange={props.handleChangeFieldsModal('rating')}
        />
        <TextField
          required
          id="user_ratings_total"
          label="User_ratings_total"
          variant="outlined"
          type="number"
          value={props.hotel.user_ratings_total}
          InputLabelProps={{
              shrink: true,
          }}
          className={classes.textField}
          margin="normal"
          helperText="Correct: 82"
          onChange={props.handleChangeFieldsModal('user_ratings_total')}
        />
    </Paper>
  );
}

Raiting.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Raiting);