export default {
    RoomsType : {
        roomsTypeHeader : 'Доступные номера',
        name :['Ванная комната','Удобства в номере'],
        services : [
            ['Душ','Ванная','Биде','Гигиенический душ','Туалет','Туалетные принадлежности','Полотенца',
            'Халат','Фен','Одноразовые щетки','Джакузи','Стиральная машина',],
            ['Мини-бар','DVD-плеер','Радиаторы отопления','Тапочки','Спутниковые каналы','Интернет',
            'Телевизор с плоским экраном','Паркетные полы','Постельное белье','Москитная сетка',
            'Шкаф/гардероб','Письменный/журнальный столик','Кондиционер','Терасса','Балкон',
            'Ковровое покрытие','Окна в пол','Нераскладной диван','Кресла','Электрический чайник',
            'Микроволновка','Холодильник','Плита','Столовые приборы',],
        ],
    },
    Services : {
        name: ['Спорт и отдых', 'Бассейн & SPA', 'Территория', 'Питание и напитки','Общие',
                'Персонал говорит', 'WiFi & Интернет',],
        services : [
            ['Пляж','Прокат лодок/катамаранов','Прокат гироскутеров','Теннисный корт','Детский клуб',
            'Детская площадка','Верховая езда','Велосипедные маршруты','Прокат велосипедов' ,'Дартс',
            'Настольный теннис','Бильярд','Рыбалка','Зоопарк','Пейнтбол','Веревочный парк','Батут',],
            ['Солярий','Массаж','СПА зона','Косметический кабинет','Баня: русская/финская','Сауна',
            'Бассейн','Крытый бассейн','Детский бассейн','Бассейн с подогревом',],
            ['Лес','Сад','Терраса','Барбекю','Аренда шампуров/мангалов','Беседки платно','Беседки бесплатно',
            'Скамейки','Газон','Пасека','Магазин продуктовый','Магазин сувенирный','Пруд','Озеро','Речка',],
            ['Ресторан','Кафе','Бар','Кальян','Диетическое питание','Алкогольные напитки','Приготовление на мангале',
            'Еда из печи','Детское меню','Самостоятельное приготовление','Доставка еды в номер',],
            ['Медицинский персонал','Места для курения','Экскурсионное бюро','Экспресс регистрация заезда/выезда',
            'Индивидуальная регистрация','Терминал','Оплата банковской картой','Круглосуточная стойка регистратуры',
            'Охрана территории','Конференц зал','Организация мероприятий','Парковка платная','Парковка бесплатная',
            'Размещение с животными','Детские кровати в номер','Уборка номера','Няня',],
            ['Английский', 'Русский', 'Украинский'],
            ['На территории','В номерах','В ресторане','В главном здании',],
        ]
    },
    ListHotels : {
        title: 'Не только отели... Откройте для себя идеальный комфорт домов и апартаментов',
        distanceFromKiev : 'Расстояние от Киева : ',
        typeOfAccommodationText : 'Тип проживания : ',
        typeOfAccommodation: ['Коттедж', 'Бунгало', 'Главный корпус', 'Вилла', 'Палатка'],
        foodAndDrinkText : 'Еда и напитки : ',
        foodAndDrink : ['Ресторан','Кафе','Бар','Кальян','Диетическое питание','Алкогольные напитки','Приготовление на мангале',
        'Еда из печи','Детское меню','Самостоятельное приготовление','Доставка еды в номер',],
        activitiesText : 'Развлечения: ',
        activities: ['Пляж','Прокат лодок/катамаранов','Прокат гироскутеров','Теннисный корт','Детский клуб',
                'Детская площадка','Верховая езда','Велосипедные маршруты','Прокат велосипедов' ,'Дартс',
                'Настольный теннис','Бильярд','Рыбалка','Зоопарк','Пейнтбол','Веревочный парк','Батут',],
        descriptionTitle: 'Про комплекс',
    },
  };
  // ['Пляж', 'Снаряжение для тенниса', 'Вечерняя программа', 'Детский клуб',
            // 'Анимационный персонал', 'Верховая езда Оплачивается отдельно', 'Велоспорт', 'Маршруты для пеших прогулок',
            // 'Библиотека', 'Прокат велосипедов (оплачивается отдельно)', 'Дартс', 'Настольный теннис',
            // 'Бильярд','Детская игровая площадка','Игровая комната','Рыбная ловля',],

            // ['Трансфер от/до аэропорта (оплачивается отдельно)','Трансфер (оплачивается отдельно)',
            // 'Места для курения','Гипоаллергенный номер','Москитная сетка','Деревянный или паркетный пол',
            // 'Отопление','Упакованные ланчи','Церковь/храм','Звукоизолированные номера','Сувенирный магазин',
            // 'Люкс для новобрачных','VIP-удобства в номерах','Семейные номера','Номера для некурящих',
            // 'Доставка еды и напитков в номер',],
            
            // ['Солярий', 'Частная пляжная зона', 'Барбекю', 'Терраса', 'Сад',],

            // ['Вино / Шампанское', 'Детское питание', 'Специальное диетическое питание (по запросу)', 'Завтрак в номере',
            // 'Бар', 'Ресторан', 'Мини-бар'],

            // ['Индивидуальная регистрация заезда / отъезда', 'Экскурсионное бюро', 'Экспресс регистрация заезда / отъезда',
            //  'Круглосуточная стойка регистрации',],

            //  ['Английский', 'Русский', 'Украинский'],

            //  ['Свободно! Wi-Fi предоставляется в номерах отеля бесплатно. ',
            //    'Wi-Fi не доступен в номерах отеля.', 'Wi-Fi доступен в номерах отеля. За дополнительную оплату!'],

            //  ['На территории отеля есть бесплатная общественная парковка (требуется предварительный заказ).',
            //                   'Охраняемая парковка'],

            //  ['Няня / Услуги по уходу за детьми', 'Детский клуб'],