export function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  console.log("authHeader", user.token);
  if(user && user.token) {
    return { 'Authorization': 'Bearer ' + user.token,
    'Content-Type': 'application/json' };
  } else {
    return {};
  }
}
