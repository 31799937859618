import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Class from '@material-ui/icons/Class';
import Exit from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';


  
function mainListItems(props) {
  return (
  <div>
    <ListItem button name="Dashboard" onClick={e => props.onClick(e, "Dashboard")}> 
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard"/>
    </ListItem>
  
    <ListItem button onClick={e => props.onClick(e, "showHotels")}>
      <ListItemIcon>
        <Class />
      </ListItemIcon>
      <ListItemText primary="Hotels" />
    </ListItem>

    <ListItem button onClick={e => props.onClick(e, "showAddHotel")}>
      <ListItemIcon>
        <Class />
      </ListItemIcon>
      <ListItemText primary="AddHotel" />
    </ListItem>

    <ListItem button onClick={e => props.onClick(e, "showGoogleApi")}>
      <ListItemIcon>
        <Class />
      </ListItemIcon>
      <ListItemText primary="GoogleApi" />
    </ListItem>

    <ListItem button onClick={e => props.onClick(e, "showUsers")}>
      <ListItemIcon>
        <Class />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItem>
    
    <ListItem button component={Link} to="/login">
      <ListItemIcon>
        <Exit />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  </div>
)};

export default mainListItems;