import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    notification: {
      padding: theme.spacing.unit,
    },
  });


function ShowRooms(props) {
 const { classes } = props;
 return (
    <>
        {props.roomstype.length>0 ? 
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                      <TableCell align="left">Room Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left">Delete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.roomstype.map((room, id) => (
                        <TableRow key={id}>
                            <TableCell align="left" component="th" scope="row">
                                {room.roomname.map((name, id) => (
                                    <div key={id}>{name.lang} : {name.value}<br/></div>
                                ))}
                            </TableCell>
                            <TableCell align="left" component="th" scope="row">
                                {room.description.map((desc,id) => (
                                    <div key={id}>{desc.lang} : {desc.value}<br/></div>
                                ))}
                            </TableCell>
                            <TableCell align="left" component="th" scope="row">
                                <Button key={id} variant="contained" onClick={props.deleteRoomFromTypeOfRoom(id)}>
                                    Delete room {id}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>   
            :   <Typography color="secondary" variant="h6" className={classes.notification}>
                     We don't add any Rooms yet, plz add new Room for Hotel 
                </Typography>}
     </>

 )
}

ShowRooms.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(ShowRooms);