import { authHeader, authHeaderForImage } from '../_helpers';
import {config} from '../_config';


export const hotelsService = {
    getAll,
    create,
    deleteHotel,
    update,
    findByName,
    findById,
    uploadImgHotel,
    uploadImgRoom,
    deleteImgHotel,
    deleteImgRoom,
    uploadImgHotelUpdate,
    uploadImgRoomUpdate,
    deleteOneImgRoom,
    uploadImgEditRoomUpdate
};

function uploadImgRoom(formData) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderForImage(),
        body: formData
    };

    return fetch(`${config.apiUrl}/hotels/uploadimgroom`, requestOptions).then(handleResponse);
}

function uploadImgHotel(formData) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderForImage(),
        body: formData
    };

    return fetch(`${config.apiUrl}/hotels/upload`, requestOptions).then(handleResponse);
}

function uploadImgRoomUpdate(formData) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderForImage(),
        body: formData
    };

    return fetch(`${config.apiUrl}/hotels/uploadImgRoomUpdate`, requestOptions).then(handleResponse);
}

function uploadImgEditRoomUpdate(formData) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderForImage(),
        body: formData
    };

    return fetch(`${config.apiUrl}/hotels/uploadImgEditRoomUpdate`, requestOptions).then(handleResponse);
}

function uploadImgHotelUpdate(formData) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderForImage(),
        body: formData
    };

    return fetch(`${config.apiUrl}/hotels/uploadImgHotelUpdate`, requestOptions).then(handleResponse);
}

function create(hotel) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(hotel)
    };

    return fetch(`${config.apiUrl}/hotels/create`, requestOptions).then(handleResponse);
}

function update(hotel) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(hotel)
    };
    return fetch(`${config.apiUrl}/hotels/update`, requestOptions).then(handleResponse);
}

function deleteImgHotel(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${config.apiUrl}/hotels/deleteImgHotel`, requestOptions).then(handleResponse);
}
function deleteImgRoom(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${config.apiUrl}/hotels/deleteImgRoom`, requestOptions).then(handleResponse);
}

function deleteOneImgRoom(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${config.apiUrl}/hotels/deleteOneImgRoom`, requestOptions).then(handleResponse);
}


function findByName(hotelname) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(hotelname)
    };
    return fetch(`${config.apiUrl}/hotels/find`, requestOptions).then(handleResponse);
}

function findById(hotel_id) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({hotel_id})
    };
    return fetch(`${config.apiUrl}/hotels/findById`, requestOptions).then(handleResponse);
}

function deleteHotel(hotelname) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(hotelname),
    };
    return fetch(`${config.apiUrl}/hotels/delete`, requestOptions).then(handleResponse);
}

function getAll(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/hotels/all`, requestOptions).then(handleResponse);
}

function handleResponse(response){
    return response.text().then(text => {
        console.log("text handleResponse text ", text);
        const data = text && JSON.parse(text);
        console.log("data handleResponse dat " , data);
        if(!response.ok) {
            console.log("response not OK", response.status);
            if(response.status === 401) {
                // auto logout if 401 response returned from api
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            console.log("error response " ,error);
            return Promise.reject(error);
        }
        return data;
    });
}