export default {
    RoomsType : {
        roomsTypeHeader : 'Available rooms',
        name :['Bathroom','Room Facilities'],
        services : [
            ['Shower','Bathroom','Bidet','Hygienic shower','Toilet','Free toiletries','Towels',
            'Bathrobe','Hairdryer','Disposable brushes','Jacuzzi','Washer',],
            ['Minibar','DVD Player','Heating radiators','Slippers','Satellite channels','Internet','Flat-screen TV',
            'Hardwood floors','Linens','Mosquito net','Wardrobe','Writing/coffee table','Air conditioning',
            'Terrace','Balcony','Carpeted','Floor windows','Sofa couch','Armchairs','Electric kettle',
            'Microwave','Fridge','Plate','Cutlery',],
        ],
    },
    Services : {
        name: ['Activities', 'Pool & SPA', 'Territory','Food & Drink','General',
                'Languages Spoken','WiFi & Internet', ],
        services : [
            ['Beach', 'Boat/catamaran rental', 'GyroScooter rental', 'Tennis court',
            'Kids\' club', 'Playground','Horseback riding' ,'Cycling routes','Bicycle rental',
            'Darts','Ping-pong','Pool table','Fishing','Zoo','Paintball','Rope park','Trampoline'],
            ['Solarium','Massage','SPA area','Beauty parlor','Bath: russian/finnish','Sauna',
            'Pool','Indoor pool','Children\'s pool','Heated swimming pool',],
            ['Forest','Garden','Terrace','BBQ facilities','Rental skewers/barbecue','Altanka rental',
            'Altanka for free','Benches','Lawn','Apiary','Food shop','Gift shop','Pond','Lake','River',],
            ['Restaurant','Cafe','Bar','Hookah','Diet food','Alcoholic drinks','Cooking on the grill',
            'Food from the oven','Kids\' meals','Self cooking','Food delivery to the room',],
            ['Medical staff','Smoking area','Tour agency','Express check-in/out','Private check-in/out',
            'Terminal','Payment by credit card','24-hour front desk','Guarded territory','Conference hall',
            'Organization of events','Parking charge','Parking free','Accommodation with animals',
            'Children\'s beds in the room','Room cleaning','Nanny',],
            ['English','Russian','Ukrainian',],
            ['In the territory','In the rooms','In the restaurant','In the main building',],
        ]
    },
    ListHotels : {
        title: 'Not only hotels ... Discover the perfect comfort of houses and apartments',
        distanceFromKiev : 'Distance from Kiev : ',
        typeOfAccommodationText : 'Type of accommodation : ',
        typeOfAccommodation: ['Cottage', 'Bungalow' ,'Main Building', 'Villa', 'Tent'],
        foodAndDrinkText : 'Food and Drink : ',
        foodAndDrink : ['Restaurant','Cafe','Bar','Hookah','Diet food','Alcoholic drinks','Cooking on the grill',
        'Food from the oven','Kids\' meals','Self cooking','Food delivery to the room',],
        activitiesText : 'Entertainment: ',
        activities: ['Beach', 'Boat/catamaran rental', 'GyroScooter rental', 'Tennis court',
                'Kids\' club', 'Playground','Horseback riding' ,'Cycling routes','Bicycle rental',
                'Darts','Ping-pong','Pool table','Fishing','Zoo','Paintball','Rope park','Trampoline'],
        descriptionTitle: 'About the Complex',
    },
  };