import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {regions_en, regions_uk, regions_ru} from '../_config/regions';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
      },
  });  

  function TabContainer(props) {
    return (
        <>
         {props.children}
        </>
    );
  }
  
 function MultiLangHotelInfo(props) {
     const { classes } = props;
     return (
        <>
         <AppBar color="default" position="static">
                <Tabs value={props.value} onChange={props.handleChangeTab}>
                  <Tab label="English" />
                  <Tab label="Ukraine" />
                  <Tab label="Russian" />
                </Tabs>
        </AppBar>
              {props.value === 0 && <TabContainer>
                                        <TextField
                                            id="hotelname_en"
                                            label="Hotel Name on English"
                                            className={classes.textField}
                                            onChange={props.handleChangeHotelName('en')}
                                            value={props.hotelname.en}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                        <TextField
                                            required
                                            id="city_en"
                                            label="City on English"
                                            variant="outlined"
                                            value={props.city[0].value}
                                            className={classes.textField}
                                            margin="normal"
                                            onChange={props.handleChangeCity('en')}
                                        />
                                        <TextField
                                            select
                                            id="region_en"
                                            label="Region on English"
                                            variant="outlined"
                                            className={classes.textField}
                                            margin="normal"
                                            value={props.region[0].value}
                                            onChange={props.handleChangeRegion('en')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                className: classes.menu,
                                                },
                                            }}
                                            helperText="Region"
                                        >
                                            {regions_en.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                        <TextField
                                            required
                                            id="description_en"
                                            label="Description on English"
                                            variant="outlined"
                                            fullWidth
                                            value={props.description[0].value}
                                            className={classes.textField}
                                            margin="normal"
                                            multiline={true}
                                            rows={4}
                                            rowsMax={8}
                                            onChange={props.handleChangeDescription("en")}
                                            />
                                    </TabContainer>
                                    }
              {props.value === 1 && <TabContainer>
                                        <TextField
                                            id="hotelname_uk"
                                            label="Hotel Name on Ukraine"
                                            className={classes.textField}
                                            onChange={props.handleChangeHotelName('uk')}
                                            value={props.hotelname.uk}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                        <TextField
                                            required
                                            id="city_uk"
                                            label="City on Ukraine"
                                            variant="outlined"
                                            value={props.city[1].value}
                                            className={classes.textField}
                                            margin="normal"
                                            onChange={props.handleChangeCity('uk')}
                                        />
                                        <TextField
                                            select
                                            id="region_uk"
                                            label="Region on Ukraine"
                                            variant="outlined"
                                            className={classes.textField}
                                            margin="normal"
                                            value={props.region[1].value}
                                            onChange={props.handleChangeRegion('uk')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                className: classes.menu,
                                                },
                                            }}
                                            helperText="Region"
                                        >
                                            {regions_uk.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                        <TextField
                                            required
                                            id="description_uk"
                                            label="Description on Ukraine"
                                            variant="outlined"
                                            fullWidth
                                            value={props.description[1].value}
                                            className={classes.textField}
                                            margin="normal"
                                            multiline={true}
                                            rows={4}
                                            rowsMax={8}
                                            onChange={props.handleChangeDescription("uk")}
                                            />
                                    </TabContainer>}
              {props.value === 2 &&<TabContainer>
                                        <TextField
                                            id="hotelname_ru"
                                            label="Hotel Name on Russian"
                                            className={classes.textField}
                                            onChange={props.handleChangeHotelName('ru')}
                                            value={props.hotelname.ru}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                        <TextField
                                            required
                                            id="city_ru"
                                            label="City on Russia"
                                            variant="outlined"
                                            value={props.city[2].value}
                                            className={classes.textField}
                                            margin="normal"
                                            onChange={props.handleChangeCity('ru')}
                                        />
                                        <TextField
                                            select
                                            id="region_ru"
                                            label="Region on Russian"
                                            variant="outlined"
                                            className={classes.textField}
                                            margin="normal"
                                            value={props.region[2].value}
                                            onChange={props.handleChangeRegion('ru')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                className: classes.menu,
                                                },
                                            }}
                                            helperText="Region"
                                        >
                                            {regions_ru.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                        <TextField
                                            required
                                            id="description_ru"
                                            label="Description on Russian"
                                            variant="outlined"
                                            fullWidth
                                            className={classes.textField}
                                            value={props.description[2].value}
                                            margin="normal"
                                            multiline={true}
                                            rows={4}
                                            rowsMax={8}
                                            onChange={props.handleChangeDescription("ru")}
                                            />
                                    </TabContainer>}
        </>
     )
 }

MultiLangHotelInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(MultiLangHotelInfo);