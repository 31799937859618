import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux'

import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import translate from '../../HOC/translate';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  checkbox : {
    padding : 4,
  },
  legend : {
    marginBottom: 20,
  }
});

function ServicesRoom(props) {
    const { classes, services, strings} = props;
    return (
     <>
      {services.map((service, indexService) => (
        <Grid item xs={12} sm={6} md={3} key={indexService}> 
          <FormControl component="fieldset" className={classes.formControl} key={indexService}>
            <FormLabel component="legend" className={classes.legend}>{service.name}</FormLabel>
              <FormGroup>
                {service.items.map((item, indexItem) => (
                  <FormControlLabel
                    control={
                      <Checkbox checked={item.selected} 
                          onChange={props.handleChangeOfService(service.name, item.name)} 
                          value={item.name}
                          className = {classes.checkbox}
                        />
                    }
                    label={strings.services[indexService][indexItem]}
                    key={indexItem}
                  />  
                ))}
              </FormGroup>
            <FormHelperText>Be careful</FormHelperText>
          </FormControl>
      </Grid>                
      ))}
    </>
    );
  }

  ServicesRoom.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  const ServicesRoomCompose = compose(
    withStyles(styles),
    translate('RoomsType'),
  )(ServicesRoom);
  
  export {ServicesRoomCompose as ServicesRoom};