import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {hotelsService} from '../_services';
import {googleService} from '../_services';

import SearchHotel from './SearchHotel';
import PlaceId from './PlaceId';
import AddPlaceId from './AddPlaceId';
import ModalError from './Modal/ModalError';
import ModalSuccess from './Modal/ModalSuccess';
import GoogleHotelMainInfo from './FromGoogle/GoogleHotelMainInfo';
import GoogleRaiting from './FromGoogle/GoogleRaiting';
import GoogleReviews from './FromGoogle/GoogleReviews';
import HotelMainInfo from './Hotel/HotelMainInfo';
import Raiting from './Hotel/Raiting';
import Reviews from './Hotel/Reviews';

const styles = theme => ({
    root: {
      display: 'flex',
    },
    title: {
        paddingTop: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
      },
    menu: {
        width: 200,
     },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
});

const language = [
    {
      value: 'ru',
      label: 'Russian Language',
    },
    {
      value: 'uk',
      label: 'Ukraine Language',
    },
    {
      value: 'en',
      label: 'English Language',
    },
    
  ];

class GoogleApi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            selectedItem: [],
            hotel: {},
            hotels: [],
            hotelname: [],
            isLoadedHotels: false,
            isLoadedHotel: false,
            isLoadedFromGoogleApi : false,
            addPlaceId : false,
            errorMsg :'',
            googleApiResult : {},
            errorDialogOpen: false,
            successDialogOpen: false,
            dataForSearchPlaceId : {phone: '', text: ''},
            languageForReview : 'ru',
        };
    }

    componentDidMount() {
        hotelsService.getAll()
          .then(data => this.setState({hotels: data, isLoadedHotels: true}))
          .catch(err =>  this.setState({errorMsg : err,errorDialogOpen: true,}));
     }

     handleClickUpdateHotelButton = () => {
        hotelsService.update(this.state.hotel)
            .then((successMsg) => this.setState({successUpdateMsg : successMsg,successDialogOpen: true,})) 
            .catch((err) => this.setState({ errorMsg : err, errorDialogOpen: true,}))
     }

    //search field -> find hotel by id
    handleOnChangeHotelName = (hotel) => {
        hotelsService.findById(hotel.id)
            .then(hotel => this.setState({hotel : hotel, isLoadedHotel: false,}))
            //we must check did hotel have place_id if not we open dialog for ADD place_id
            .then(() => !this.state.hotel.place_id ? this.setState({addPlaceId: true,}) : this.setState({isLoadedHotel: true,}))
            .catch(err => this.setState({errorMsg : err,errorDialogOpen: true, }));
    }

    handleChangeFieldsModal = name => event => {
        this.setState({hotel :{
          ...this.state.hotel,
          [name] : event.target.value
          }
        });
    };

    handleChangeLanguageForReview = name => event => {
        this.setState({
          [name]: event.target.value,
        });
      };

    handleChangeFieldsFindPlaceId = name => event => {
        this.setState({dataForSearchPlaceId :{
            ...this.state.dataForSearchPlaceId,
            [name] : event.target.value
            }
          });
    }

    handleClickCloseButton = () => {
        this.setState({hotel: {}, googleApiResult:{}, 
                isLoadedHotel: false, isLoadedFromGoogleApi: false, addPlaceId: false,})
    }

    //Google API result.status : 
    //OK, UNKNOWN_ERROR, ZERO_RESULTS,OVER_QUERY_LIMIT
    //REQUEST_DENIED,  INVALID_REQUEST, NOT_FOUND
    handleClickUploadGoogleApiButton = () => {
        console.log(this.state.languageForReview)
        googleService.getInfoByPlaceId(this.state.hotel.place_id, this.state.languageForReview)
         .then(result => this.setState({googleApiResult: result, isLoadedFromGoogleApi: false,}))
         .then(() => (this.state.googleApiResult.status==='OK' ? this.setState({isLoadedFromGoogleApi : true}) :
                        this.setState({errorMsg : this.state.googleApiResult.status + " need place_id",
                         errorDialogOpen: true, })))
         .catch(err => this.setState({errorMsg : err,
                        errorDialogOpen: true, }))
    }

    //Google API try to find place_id use phone, text
    handleClickTryFindPlaceIdAutomaticallyButton = name => event => {
        //for phone number GOOGLE API
        //Note that the international call prefix "+" has been encoded to %2B so that this request 
        //is a compliant URL. Left unencoded, the + prefix would be decoded to a space on the server, 
        //resulting in an invalid phone number lookup.

        const { phone } = this.state.dataForSearchPlaceId;
        const { text } = this.state.dataForSearchPlaceId;
        //prepare phone for google delte space and change prefix "+" to %2B
        const phoneGoogle = "%2B" + phone.replace(/\s+/g, '').substr(1, phone.length);
        if(name==='phone')
            googleService.findPlaceId(phoneGoogle, 0)
                .then((result) => (result.status ==='OK' 
                        ? this.setState({hotel : { ...this.state.hotel, place_id : result.candidates[0].place_id}, 
                            addPlaceId: false, isLoadedHotel: true,})
                        : this.setState({errorMsg : result.status + " with phone: " + phone, errorDialogOpen: true,})))
                .catch(err => this.setState({errorMsg : err, errorDialogOpen: true, }));
        else 
            googleService.findPlaceId(text, 1)
                    .then((result) => (result.status ==='OK' 
                            ? this.setState({hotel : { ...this.state.hotel, place_id : result.candidates[0].place_id}, 
                                addPlaceId: false, isLoadedHotel: true,})
                            : this.setState({errorMsg : result.status + " with text: " + text, errorDialogOpen: true,})))
                    .catch(err => this.setState({errorMsg : err, errorDialogOpen: true, }));
    }

    handleModalError = () => {
        this.setState({errorDialogOpen: !this.state.errorDialogOpen})
      };
    
    handleModalSuccess = () => {
        this.setState({successDialogOpen: !this.state.successDialogOpen, 
            hotel: {}, googleApiResult:{}, isLoadedHotel: false, isLoadedFromGoogleApi: false, addPlaceId: false,})
    };
    
    //check Reviews from Hotel and from Google, make new Array with unique reviews and add to Hotel
    handleClickAddReviewFromGoogle = () => {
        //check if we loaded data from GOOGLE API before add reviews
        if(this.state.isLoadedFromGoogleApi) {
            const reviewsGoogle = this.state.googleApiResult.result.reviews;
            const reviewsHotel = this.state.hotel.reviews;
            const reviewsUnique = [];
            //concat two Array's
            //map over Array
            //filter result in new Array
            reviewsHotel.concat(reviewsGoogle).map(x => reviewsUnique.filter(a => a.author_name === x.author_name && 
                a.text === x.text).length > 0 ? null : reviewsUnique.push(x));
            this.setState({hotel : { ...this.state.hotel, reviews: reviewsUnique}});
        } else this.setState({errorMsg : "Before We must upload info from GOOGLE API", errorDialogOpen: true, });
    }

    render() {
        const { classes } = this.props;
        let hotelnames = [];
        //prepare array with suggestions hotelname, id
        this.state.hotels.forEach(hotel => { 
                hotelnames.push({value: hotel.hotelname.en, id: hotel._id});
                hotelnames.push({value: hotel.hotelname.uk, id: hotel._id});
            })
        return ( 
            <React.Fragment>
                {this.state.isLoadedHotels ?
                    <SearchHotel
                        hotelnames ={hotelnames}
                        handleOnChangeHotelName={this.handleOnChangeHotelName}
                    /> 
                        : <CircularProgress className={classes.progress} />}
                {this.state.isLoadedHotel && 
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                                <Button
                                    onClick={this.handleClickUpdateHotelButton}
                                    color="primary" 
                                    size="large" 
                                    variant="contained" 
                                    className={classes.button}
                                >
                                    Update Hotel
                                </Button>
                                <Button 
                                    onClick={this.handleClickCloseButton}
                                    color="secondary" 
                                    size="large" 
                                    variant="contained"  
                                    className={classes.button}
                                >
                                    Close Hotel
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                            <TextField
                                    id="select_lang"
                                    select
                                    label="Select Language for Reviews"
                                    className={classes.textField}
                                    value={this.state.languageForReview}
                                    onChange={this.handleChangeLanguageForReview('languageForReview')}
                                    SelectProps={{
                                        native: true,
                                        MenuProps: {
                                        className: classes.menu,
                                        },
                                    }}
                                    helperText="Please select language for upload Reviews"
                                    margin="normal"
                                    variant="outlined"
                                    >
                                    {language.map(option => (
                                        <option key={option.value} value={option.value}>
                                        {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Button 
                                    onClick={this.handleClickUploadGoogleApiButton}
                                    color="primary" 
                                    size="large" 
                                    variant="contained" 
                                    className={classes.button}
                                >
                                    Upload info from Google API
                                </Button>
                                <Button
                                    onClick={this.handleClickAddReviewFromGoogle}
                                    color="primary" 
                                    size="large" 
                                    variant="contained" 
                                    className={classes.button}
                                >
                                    Add Review from Google
                                </Button>                            
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="secondary">
                                Attention! Before add data from GOOGLE API, check that we found right HOTEL!
                                If Hotel is not right, set new correct PLACE_ID for hotel.
                            </Typography>
                            <PlaceId 
                                place_id={this.state.hotel.place_id}
                                handleChangeFieldsModal={this.handleChangeFieldsModal}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="title" className={classes.title} gutterBottom>
                                From Our Site, Hotel Name: {this.state.hotel.hotelname.en}, 
                                {this.state.hotel.hotelname.uk}, {this.state.hotel.hotelname.ru}
                            </Typography>
                            <HotelMainInfo 
                                hotel={this.state.hotel}
                                handleChangeFieldsModal={this.handleChangeFieldsModal}
                            />
                            <Raiting
                                hotel={this.state.hotel}
                                handleChangeFieldsModal={this.handleChangeFieldsModal}
                            />
                            <Reviews 
                                hotel={this.state.hotel}
                            />
                        </Grid>
                        {this.state.isLoadedFromGoogleApi && 
                        <Grid item xs={6}>
                            <Typography variant="title" className={classes.title} gutterBottom>
                                From Google API, Hotel Name: {this.state.googleApiResult.result.name}
                            </Typography>
                            <GoogleHotelMainInfo 
                                result={this.state.googleApiResult.result}
                                handleChangeFieldsModal={this.handleChangeFieldsModal}
                            />
                            <GoogleRaiting
                                result={this.state.googleApiResult.result}
                                handleChangeFieldsModal={this.handleChangeFieldsModal}
                            />
                            <GoogleReviews 
                                reviews={this.state.googleApiResult.result.reviews}
                            />
                        </Grid>}
                    </Grid>
                }

                {this.state.addPlaceId && 
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <AddPlaceId 
                                handleChangeFieldsModal={this.handleChangeFieldsModal}
                                handleClickCloseButton={this.handleClickCloseButton}
                                handleClickUpdateHotelButton={this.handleClickUpdateHotelButton}
                                handleClickTryFindPlaceIdAutomaticallyButton={this.handleClickTryFindPlaceIdAutomaticallyButton}
                                handleChangeFieldsFindPlaceId={this.handleChangeFieldsFindPlaceId}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="title" className={classes.title} gutterBottom>
                                From Our Site, Hotel Name: {this.state.hotel.hotelname.en}
                            </Typography>
                            <HotelMainInfo 
                                hotel={this.state.hotel}
                                handleChangeFieldsModal={this.handleChangeFieldsModal}
                            />
                        </Grid>
                    </Grid>
                    
                    }

                <ModalError 
                    open={this.state.errorDialogOpen} 
                    error={this.state.errorMsg} 
                    handleClose={this.handleModalError}
                />

                <ModalSuccess 
                    open={this.state.successDialogOpen} 
                    success={this.state.successUpdateMsg} 
                    handleClose={this.handleModalSuccess}
                />
            </React.Fragment>
        )
    }
}

GoogleApi.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GoogleApi);