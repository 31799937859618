const API_KEY = 'AIzaSyDRTUIHu4jYhlwClc4hisSKYQDKC-hQ6p4';
const proxyurl = "https://cors-anywhere.herokuapp.com/";

///////////////////////////////////// place details ///////////////////////////////////////
const URL_PLACE_DETAILS = 'https://maps.googleapis.com/maps/api/place/details/json?';
const FIELDS = 'geometry,review,user_ratings_total,website,name,rating,formatted_phone_number';
//const LANGUAGE = ['en' , 'uk' , 'ru'];

/////////////////////////////// place search ////////////////////////////////////////////////
const URL_FIND_PLACE_ID = 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=';
const INPUT_TYPE = ['phonenumber', 'textquery',];

/////////////////////////////// place search ////////////////////////////////////////////////

//https://developers.google.com/places/web-service/search

//https://maps.googleapis.com/maps/api/place/findplacefromtext/json?
// input=%2B380997444434
// &inputtype=phonenumber&fields=place_id
// &key=AIzaSyDRTUIHu4jYhlwClc4hisSKYQDKC-hQ6p4

// key — Your application's API key. This key identifies your application. See Get a key for more information.
// input — The text input specifying which place to search for (for example, a name, address, or phone number).
// inputtype — The type of input. This can be one of either textquery or phonenumber.
//  Phone numbers must be in international format (prefixed by a plus sign ("+"), 
// followed by the country code, then the phone number itself). See E.164 ITU recommendation for more information.

///////////////////////////////////// place details ///////////////////////////////////////
//https://developers.google.com/places/web-service/details#PlaceDetailsStatusCodes

export const googleService = {
    getInfoByPlaceId,
    findPlaceId,
};

function getInfoByPlaceId(place_id, lang) {
    const requestOptions = {
        method: 'GET',
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
    };
    return fetch(proxyurl + URL_PLACE_DETAILS +"placeid="+ place_id + "&fields=" + FIELDS +
            "&language=" + lang + "&key=" + API_KEY, requestOptions).then(handleResponse);
}

function findPlaceId(data, input_type_id) {
    const requestOptions = {
        method: 'GET',
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
    };
    return fetch(proxyurl + URL_FIND_PLACE_ID + data + "&inputtype=" + INPUT_TYPE[input_type_id] 
             + "&fields=place_id&key=" + API_KEY,
                     requestOptions).then(handleResponse);
}


function handleResponse(response){
    return response.text().then(text => {
        console.log("text handleResponse", text);
        const data = text && JSON.parse(text);
        console.log("data handleResponse" , data);
        if(!response.ok) {
            console.log("response not OK", response.status);
            if(response.status === 401) {
                // auto logout if 401 response returned from api
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            console.log("fffffffffffffffffff" ,error);
            return Promise.reject(error);
        }
        return data;
    });
}