import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ImageIcon from '@material-ui/icons/Image';

const styles = theme => ({
  input: {
    display: 'none',
    },
  root: {
    padding: theme.spacing.unit * 2,
  },
  header : {
    margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
  },
  body: {
    margin: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  checkbox : {
    padding : 4,
  },
  legend : {
    marginBottom: 20,
  }
});

function UploadImagesForRoom(props) {
    const { classes,} = props;
    return (
     <>
        <div className= {classes.root}>
            <div className = {classes.body}>
                    <input
                        key={props.roomId}
                        className={classes.input}
                        id={props.roomId}
                        type="file"
                        onChange={props.handleUploadImg(props.roomId)}
                        multiple
                    />
                    <label htmlFor={props.roomId}>
                        <Button key={props.roomId} variant="contained" component="span" className={classes.button}>
                        Upload Images
                        </Button>
                    </label>
                      { props.imagesRoomEdit.id === props.roomId &&
                        <List dense={true}>
                            {props.imagesRoomEdit.imgs.map((image, id) => (
                                      <ListItem key={id}>
                                          <ListItemIcon>
                                              <ImageIcon />
                                          </ListItemIcon>
                                          <ListItemText
                                              primary={image.name}
                                              secondary={'Size is ' + image.size + ' Kb'}
                                          />
                                      </ListItem>
                            ))}
                          </List> }
                      { (props.imagesRoomEdit.imgs.length>0 && props.imagesRoomEdit.id === props.roomId) &&<Button
                        variant="contained" 
                        color="primary" 
                        component="span" 
                        className={classes.button}
                        onClick={props.uploadImgEditRoomOnServer}
                      >
                          Save on Server
                      </Button> }
                </div>
        </div>
    </>
    );
  }

UploadImagesForRoom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadImagesForRoom);