import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import MultiLangHotelInfo from './MultiLangHotelInfo';
import MainInfoHotel from './MainInfoHotel';
import TypeOfAccommodation from './TypeOfAccommodation';
import ImgGalleryHotel from './ImgGalleryHotel';
import UploadImagesForHotel from './UploadImagesForHotel';
import {Services} from './Services';
import ShowRooms from './ShowRooms';

import {ServicesRoom} from './Rooms/ServicesRoom';
import MultiLangRoomInfo from './Rooms/MultiLangRoomInfo';
import MainInfoRoom from './Rooms/MainInfoRoom';
import UploadImagesForRoom from './Rooms/UploadImagesForRoom';

import ModalSuccess from './Modals/ModalSuccess';
import ModalError from './Modals/ModalError';
import ModalAddRoom from './Modals/ModalAddRoom';
import ModalEditRoom from './Modals/ModalEditRoom';

import {hotelsService} from '../_services';
import {changeStateHotel} from '../_actions';

const styles = theme => ({
  header :{
    padding: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  buttonUpdateHotel: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
  },
  paperButton: {
    textAlign : "right",
  },
  paper: {
    padding: theme.spacing.unit * 2,
  },
  dense: {
    marginTop: 19,
  }, 
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  }
});

class UpdateHotel extends Component {  

  componentDidMount() { 
    hotelsService.findByName(this.props.hotelname)
      .then(hotel => this.setState({
         hotel : hotel,
         hotelname: hotel.hotelname,
         description: hotel.description,
         city : hotel.city,
         region: hotel.region,
         typeOfAccommod : hotel.typeOfAccommod,
         services : hotel.services,
         //roomstype : hotel.roomstype,
         isLoaded : true,
        },() => console.log(this.state.hotel)))
      .catch((err) => this.setState({errorMsg : err, errorDialogOpen: true,}));
 }
 

  constructor(props) {
    super(props);
    this.state = {
      food :  [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ] ,
      beds:  [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ] ,
      roomname :  [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ],
      descriptionRoom : [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ],
      rules :  [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ],
      roomstypeObj : 
          { roomname :  [
            { lang:'en', value: ''},
            { lang:'uk', value: ''},
            { lang:'ru', value: ''},
          ] ,
            description : [
              { lang:'en', value: ''},
              { lang:'uk', value: ''},
              { lang:'ru', value: ''},
            ] ,
            rules :  [
              { lang:'en', value: ''},
              { lang:'uk', value: ''},
              { lang:'ru', value: ''},
            ] ,
            food :  [
              { lang:'en', value: ''},
              { lang:'uk', value: ''},
              { lang:'ru', value: ''},
            ] ,
            beds:  [
              { lang:'en', value: ''},
              { lang:'uk', value: ''},
              { lang:'ru', value: ''},
            ] ,
            personcount: '1', size: '30', price : '500', services : [], 
            //imagesroom : [],
          },
      servicesroom : [
            {name: 'Bathroom' ,
              items :[
                {name:'Shower', selected: false}, 
                {name:'Bathroom',selected: false},
                {name:'Bidet',selected: false}, 
                {name:'Hygienic shower',selected: false},
                {name:'Toilet',selected: false},
                {name:'Free toiletries',selected: false},
                {name:'Towels',selected: false},
                {name:'Bathrobe',selected: false},
                {name:'Hairdryer',selected: false},
                {name:'Disposable brushes',selected: false},
                {name:'Jacuzzi',selected: false},
                {name:'Washer',selected: false},],
            },    
            {name: 'Room Facilities' ,
              items :[
              {name: 'Minibar', selected: false},
              {name:'DVD Player',selected: false},
              {name:'Heating radiators',selected: false},
              {name:'Slippers',selected: false},
              {name:'Satellite channels',selected: false}, 
              {name:'Internet',selected: false},
              {name:'Flat-screen TV',selected: false},
              {name:'Hardwood floors',selected: false},
              {name:'Linens',selected: false},
              {name:'Mosquito net',selected: false},
              {name:'Wardrobe',selected: false},
              {name:'Writing/coffee table or closet',selected: false},
              {name:'Air conditioning',selected: false},
              {name:'Terrace',selected: false},
              {name:'Balcony',selected: false}, 
              {name:'Carpeted',selected: false},
              {name:'Floor windows',selected: false},
              {name:'Sofa couch',selected: false},
              {name:'Armchairs',selected: false}, 
              {name:'Electric kettle',selected: false}, 
              {name:'Microwave',selected: false}, 
              {name:'Fridge',selected: false}, 
              {name:'Plate',selected: false}, 
              {name:'Cutlery',selected: false}, 
            ],
            }
          ],
      imagesroom: [],
      imagesRoomEdit: {
         id: '',
         imgs:[],
      },
      hotelWasUpdated : false,
      succesDialogOpen: false,
      errorDialogOpen: false,
      dialogAddRoom: false,
      successUpdateMsg : '',
      isLoaded : false,
      imageshotel : [],
      hotel : {
        hotelname : {
          en:'',
          uk:'',
          ru:'',
        },
        phone: '',
        site: '',
        email: '',
        place_id: '',
        description : [
          { lang:'en', value: ''},
          { lang:'uk', value: ''},
          { lang:'ru', value: ''},
        ] ,
        city : [
          { lang:'en', value: ''},
          { lang:'uk', value: ''},
          { lang:'ru', value: ''},
        ] ,
        region : [
          { lang:'en', value: ''},
          { lang:'uk', value: ''},
          { lang:'ru', value: ''},
        ] ,
        roomstype : [],
        latitude: '12',
        longitude: '12',
        distance_from_city: '12',
        typeOfAccommod : [
          {name: 'Cottage' , selected: false}, {name: 'Bungalow' , selected: false},
          {name: 'Main Building', selected: false}, {name: 'Villa', selected: false},
          {name: 'Tent' , selected: false}],
          services: [
            {name: 'Activities' ,
              items :[
                {name:'Beach', selected: false},
                {name:'Boat/catamaran rental', selected: false},
                {name:'GyroScooter rental', selected: false},
                {name:'Tennis court', selected: false},
                {name:'Kids\' club', selected: false},
                {name:'Playground', selected: false},
                {name:'Horseback riding', selected: false},
                {name:'Cycling routes', selected: false},
                {name:'Bicycle rental', selected: false},
                {name:'Darts', selected: false},
                {name:'Ping-pong', selected: false},
                {name:'Pool table', selected: false},
                {name:'Fishing', selected: false},
                {name:'Zoo', selected: false},
                {name:'Paintball', selected: false},
                {name:'Rope park', selected: false},
                {name:'Trampoline', selected: false},],
            },
            {name: 'Pool & SPA' ,
              items :[
                {name:'Solarium', selected: false},
                {name:'Massage', selected: false},
                {name:'SPA area', selected: false},
                {name:'Beauty parlor', selected: false},
                {name:'Bath: russian/finnish', selected: false},
                {name:'Sauna', selected: false},
                {name:'Pool', selected: false},
                {name:'Indoor pool', selected: false},
                {name:'Children\'s pool', selected: false},
                {name:'Heated swimming pool', selected: false}],
            },
            {name: 'Territory' ,
              items :[
                {name:'Forest', selected: false},
                {name:'Garden', selected: false},
                {name:'Terrace', selected: false},
                {name:'BBQ facilities', selected: false},
                {name:'Skewers for rent', selected: false},
                {name:'Altanka rental', selected: false},
                {name:'Altanka for free', selected: false},
                {name:'Benches', selected: false},
                {name:'Lawn', selected: false},
                {name:'Apiary', selected: false},
                {name:'Food shop', selected: false},
                {name:'Gift shop', selected: false},
                {name:'Pond', selected: false},
                {name:'Lake', selected: false},
                {name:'River', selected: false},],
            }, 
            {name: 'Food & Drink' ,
              items :[
                {name:'Restaurant', selected: false},
                {name:'Cafe', selected: false}, 
                {name:'Bar', selected: false},
                {name:'Hookah', selected: false},
                {name:'Diet food', selected: false},
                {name:'Alcoholic drinks', selected: false},
                {name:'Cooking on the grill', selected: false},
                {name:'Food from the oven', selected: false},
                {name:'Kids\' meals', selected: false},
                {name:'Self cooking', selected: false},
                {name:'Food delivery to the room', selected: false},],
            },
            {name: 'General' ,
              items :[
                {name:'Medical staff', selected: false},
                {name:'Smoking area', selected: false},
                {name:'Tour agency', selected: false},
                {name:'Express check-in/out', selected: false},
                {name:'Private check-in/out', selected: false}, 
                {name:'Terminal', selected: false},
                {name:'Payment by credit card', selected: false},
                {name:'24-hour front desk', selected: false},
                {name:'Guarded territory', selected: false},
                {name:'Conference hall', selected: false},
                {name:'Organization of events', selected: false}, 
                {name:'Parking charge', selected: false},
                {name:'Parking free', selected: false},
                {name:'Accommodation with animals', selected: false},
                {name:'Children\'s beds in the room', selected: false}, 
                {name:'Room cleaning', selected: false},
                {name:'Nanny', selected: false},],
            },
            {name: 'Languages Spoken' ,
              items :[
                {name:'English', selected: false},
                {name:'Russian', selected: false},
                {name:'Ukrainian', selected: false},],
            },
            {name: 'WiFi & Internet' ,
              items :[
                {name: 'In the territory', selected: false},
                {name: 'In the rooms', selected: false},
                {name: 'In the restaurant', selected: false},
                {name: 'In the main building', selected: false},],
            },
          ],
      },
      errorMsg : '',
      dialogUpdateHotel: false,
      dialogEditRoom: false,
      editRoomId : '',
      valueTabForHotel: 0,
      valueTabForRoom: 0,
      hotelname : {
        en:'',
        uk:'',
        ru:'',
      },  
      region : [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ] ,
      description : [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ] ,
      city : [
        { lang:'en', value: ''},
        { lang:'uk', value: ''},
        { lang:'ru', value: ''},
      ] ,
    }
  }

  //hotel
    handleAddImgToServer = () => {

      const formData = new FormData();
      //add img's data to formData obj
      this.state.imageshotel.forEach(file => formData.append(this.state.hotel.hotelname.en, file));
      formData.append('hotelname', this.state.hotel.hotelname.en);
      hotelsService.uploadImgHotelUpdate(formData)
        .then((imgHotelPathArray) => this.setState({
        hotel: {
          ...this.state.hotel,
          imgHotelPathArray: imgHotelPathArray,
              }
          }))
        .catch(err => console.log(err));
    }

    handleAddRoomToRoomsTypeObj = () => {   
      this.setState({
        roomstypeObj : {
          ...this.state.roomstypeObj,
          food : this.state.food,
          beds : this.state.beds,
          roomname : this.state.roomname,
          description : this.state.descriptionRoom,
          rules : this.state.rules,
          services: this.state.servicesroom,
          imagesroom : this.state.imagesroom
        }, dialogAddRoom : false, imagesroom: [],
      }, () => {
         this.setState( state => {
            const roomstype = state.hotel.roomstype.push(state.roomstypeObj);
            return { ...this.state.hotel, roomstype, };
         }, () => console.log(this.state.hotel.roomstype));
      })
               
    }

    handleModalUpdateHotel = () => {
      const formDataForRoom = new FormData();
      formDataForRoom.append('hotelname', this.state.hotelname.en);
        this.state.hotel.roomstype.forEach(room => {
              formDataForRoom.append('roomname', room.roomname[0].value);
              room.imagesroom && room.imagesroom.forEach(file =>{
                   formDataForRoom.append(room.roomname[0].value, file);
                  })
          });
      hotelsService.update(this.state.hotel)
        .then(hotelsService.uploadImgRoomUpdate(formDataForRoom).catch((err) => 
              this.setState({errorMsg: err, errorDialogOpen: true,})))
        .then((successMsg) => this.setState({
          successUpdateMsg : successMsg,
          succesDialogOpen: true,
        })) 
        .catch((err) => this.setState({
          errorMsg : err,
          errorDialogOpen: true,
        }))
    };  

    handleDeleteImgHotel = imgname => event => {
      const data = {imgname : imgname, hotelname: this.state.hotel.hotelname}
      const imgHotelPathArray =  this.state.hotel.imgHotelPathArray;
      imgHotelPathArray.splice(imgHotelPathArray.indexOf(imgname), 1);
      hotelsService.deleteImgHotel(data)
        .then(this.setState({
          hotel: {
            ...this.state.hotel,
            imgHotelPathArray: imgHotelPathArray,
          }
        }))
        .catch(err => console.log(err));
    }

    handleChangeDescription = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const description = state.description.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { description, }
        }, () => this.setState({ hotel: {
            ...this.state.hotel,
            description : this.state.description,
          }
        }))
    };
    
    handleChangeHotelName = lang  => event => {
        this.setState({hotelname :{
          ...this.state.hotelname,
          [lang] : event.target.value
          }
        }, () => this.setState({hotel : {
          ...this.state.hotel,
          hotelname : this.state.hotelname
        }}));
    }; 


    handleChangeFieldsModal = name => event => {
      this.setState({hotel :{
        ...this.state.hotel,
        [name] : event.target.value
        }
      });
    };


    handleChangeRegion = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const region = state.region.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
          else return elem
      });
        return { region, }
        }, () => this.setState({hotel : {
          ...this.state.hotel,
          region : this.state.region
        }}));
    };

    handleChangeCity = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const city = state.city.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
          else return elem
      });
        return { city, }
        }, () => this.setState({hotel : {
          ...this.state.hotel,
          city : this.state.city
        }}));
    };

    handleChangeAccomod = name => event => {
      //change selected state in array of obj
      this.setState( state => {
        const typeOfAccommod = state.typeOfAccommod.map((accommod) => { 
          if(accommod.name === name) return {...accommod, selected: !accommod.selected}
          else return accommod
      });
        return { typeOfAccommod, }
        },() => this.setState({hotel : {
          ...this.state.hotel,
          typeOfAccommod : this.state.typeOfAccommod
        }}));
    };

    handleChangeServices = (serviceName, itemName) => event => {
      
      //state has nested arrays of objects. we changed selected true\false
      //1 map over service
      //2 map over items of service
      //3 change state of item of service
      //4 bec. setState  is async, we use callback for set state hotel.services
      this.setState({
        services : this.state.services.map((service, index) => {
          let items;
          if(service.name === serviceName) {
              items = service.items.map((item, index) => {
                  if(item.name === itemName) {
                      return {
                        ...item, selected: !item.selected
                      };
                    }
                  return item;
                }); 
              return {
                    ...service,
                    items : items,
                  };
            }
              return service;
          })
            }, () => this.setState({
              hotel: {
                ...this.state.hotel,
                services : this.state.services
              }
            })) 
    };

    handleUploadImg = (event) => {
      if(!this.state.hotelWasUpdated) { 
          event.preventDefault();
          const imageshotel = Array.from(event.target.files)
          this.setState({ imageshotel : imageshotel});
        } else this.setState({errorDialogOpen: !this.state.errorDialogOpen,
          errorMsg: 'Hotel was update, reload page!'})
    };

//room
    uploadImgEditRoomOnServer = () => {

      //prepare formData : imgs, roomId, hotelname.en
      const formData = new FormData();
      const roomstype = this.state.hotel.roomstype;
      const imagesRoomEdit = this.state.imagesRoomEdit;

      imagesRoomEdit.imgs.forEach(file => 
          formData.append(roomstype[imagesRoomEdit.id].roomname[0].value, file));
      
      formData.append('hotelname', this.state.hotel.hotelname.en);
      formData.append('roomId', this.state.imagesRoomEdit.id);

      //upload imgs and update hotel, then set new state for hotel
      hotelsService.uploadImgEditRoomUpdate(formData)
        .then((imgs) => { roomstype[imagesRoomEdit.id].imgs = imgs})
        .then(() => this.setState({hotel : { ...this.state.hotel , roomstype: roomstype },}))
        .catch(err => console.log(err));
    }

    handleDeleteImgRoom = (imgname, id) => event => {
      const data = {imgname : imgname, id: id, hotelname: this.state.hotel.hotelname};
      let roomstype = this.state.hotel.roomstype;
      let imgs = roomstype[id].imgs.filter((img) => img!==imgname);
      roomstype[id].imgs = imgs;
      hotelsService.deleteOneImgRoom(data)
        .then(() => this.setState({ hotel : { ...this.state.hotel , roomstype: roomstype }}))
        .catch(err => console.log(err));
    }

    deleteRoomFromTypeOfRoom = id => event => {
      let data = {roomname : this.state.hotel.roomstype[id].roomname[0].value, hotelname: this.state.hotel.hotelname}
      hotelsService.deleteImgRoom(data).catch(err => console.log(err));
      this.setState(state => {
                      const roomstype = state.hotel.roomstype.filter((item, j) => id !== j);
                      return { hotel : { ...this.state.hotel, roomstype : roomstype,} };
                });
    }

    handleModalEditRoom = id => event => {
      this.setState({dialogEditRoom: !this.state.dialogEditRoom, editRoomId: id,})
    };

    handleModalEditRoomClose = () => {
      this.setState({dialogEditRoom: false, editRoomId: '',})
    };

    handleChangeDescriptionRoom = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const descriptionRoom = state.descriptionRoom.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
          else return elem
      });
        return { descriptionRoom, }
        })
    };

    handleEditDescriptionRoom = (lang, id)  => event => {
      let value = event.target.value;
      let roomstype = this.state.hotel.roomstype;
      let description = roomstype[id].description.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
          else return elem});
      roomstype[id].description = description;
      this.setState({ hotel : { ...this.state.hotel , roomstype: roomstype }});
    };

    handleModalAddRoom = () => {
      !this.state.hotelWasUpdated ? this.setState({dialogAddRoom: !this.state.dialogAddRoom})
        : this.setState({errorDialogOpen: !this.state.errorDialogOpen,
          errorMsg: 'Hotel was update, reload page!'})
    };
    
    handleEditRoomName = (lang, id)  => event => {
      let value = event.target.value;
      let roomstype = this.state.hotel.roomstype;
      let roomname = roomstype[id].roomname.map((elem) => { 
            if(elem.lang === lang) 
              return {...elem, value: value}
           else return elem });
      roomstype[id].roomname = roomname;
      this.setState({ hotel : { ...this.state.hotel , roomstype: roomstype }});
    };

    handleChangeRoomName = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const roomname = state.roomname.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { roomname, }
        })
    };

    handleEditRoomRules = (lang, id)  => event => {
      let value = event.target.value;
      let roomstype = this.state.hotel.roomstype;
      let rules = roomstype[id].rules.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
          else return elem });
      roomstype[id].rules = rules;
      this.setState({ hotel : { ...this.state.hotel , roomstype: roomstype }});
    };

    handleChangeRoomRules = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const rules = state.rules.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { rules, }
        })
    };

    handleEditRoomFood = (lang, id)  => event => {
      let value = event.target.value;
      let roomstype = this.state.hotel.roomstype;
      let food = roomstype[id].food.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem});
      roomstype[id].food = food;
      this.setState({ hotel : { ...this.state.hotel , roomstype: roomstype }});

    };

    handleChangeRoomFood = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const food = state.food.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { food, }
        })
    };

    handleEditRoomBeds = (lang, id)  => event => {
      let value = event.target.value;
      let roomstype = this.state.hotel.roomstype;
      let beds = roomstype[id].beds.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem});
      roomstype[id].beds = beds;
      this.setState({ hotel : { ...this.state.hotel , roomstype: roomstype }});

    };

    handleChangeRoomBeds = lang  => event => {
      let value = event.target.value;
      this.setState( state => {
        const beds = state.beds.map((elem) => { 
          if(elem.lang === lang) 
            return {...elem, value: value}
         else return elem
      });
        return { beds, }
        })
    };

    
    handleEditFieldsRoom = (name, id) => event => {
      //take value from fields
      let value = event.target.value;
      //create new array(because state direct immutable)
      let roomstype = this.state.hotel.roomstype;
      //set new value for fields
      roomstype[id][name]=value;
      //set new state
      this.setState({ hotel : { ...this.state.hotel , roomstype: roomstype }});
    };

    handleChangeFieldsRoom = name => event => {
      this.setState({roomstypeObj :{
        ...this.state.roomstypeObj,
        [name] : event.target.value
        }
      });
    };

    handleUploadImgForRoom = (event) => {
      if(!this.state.hotelWasUpdated) { 
        event.preventDefault();
        const imagesroom = Array.from(event.target.files)
        this.setState({ imagesroom : imagesroom});
    } else this.setState({errorDialogOpen: !this.state.errorDialogOpen,
      errorMsg: 'Hotel was update, reload page!'})
    };

    handleUploadImgForEditRoom = (roomId) => (event) => {
      if(!this.state.hotelWasUpdated) { 
        event.preventDefault();
        const imgs = Array.from(event.target.files)
        this.setState( {imagesRoomEdit : { ...this.state.imagesRoomEdit, 
          id: roomId, imgs : imgs} });
      } else this.setState({errorDialogOpen: !this.state.errorDialogOpen,
            errorMsg: 'Hotel was update, reload page!'})
    };

    handleEditServicesRoom = (serviceName, itemName) => event => {
      let roomstype = this.state.hotel.roomstype;
      const id = this.state.editRoomId;
      let services = roomstype[id].services.map((service, index) => {
        let items;
        if(service.name === serviceName) {
                  items = service.items.map((item, index) => {
                      if(item.name === itemName) {
                            return {...item, selected : !item.selected};
                    }
                    return item;
                  });
              return {...service, items : items,};
            }
          return service;
      });
      roomstype[id].services = services;
      this.setState({ hotel : { ...this.state.hotel , roomstype: roomstype }});
    };

    handleChangeServicesRoom = (serviceName, itemName) => event => {
      
      //state has nested arrays of objects. we changed selected true\false
      //1 map over service
      //2 map over items of service
      //3 change state of item of service
      //4 bec. setState  is async, we use callback for set state hotel.services
      this.setState({
        servicesroom : this.state.servicesroom.map((service, index) => {
          let items;
          if(service.name === serviceName) {
              items = service.items.map((item, index) => {
                    if(item.name === itemName) {
                        return {...item, selected: !item.selected};
                      }
                    return item;
                  }); 
                return {...service,items : items,};
              }
              return service;
          })
      }) 
    };


  //Tab for multilanguage
  handleChangeTabForHotel = (event, value) => {
    this.setState({ valueTabForHotel : value });
  };
  handleChangeTabForRoom = (event, value) => {
    this.setState({ valueTabForRoom : value });
  };

//success dialog state
  handleModalSuccess = () => {
    const { dispatch } = this.props;
    dispatch(changeStateHotel("updated"));
    this.setState({succesDialogOpen: !this.state.succesDialogOpen, hotelWasUpdated: !this.state.hotelWasUpdated})
  };

  handleModalError = () => {
    this.setState({errorDialogOpen: !this.state.errorDialogOpen,})
  };
 
  handleCloseUpdatePage = () => {
    const { dispatch } = this.props;
    dispatch(changeStateHotel("updated"));
  }

 render() {
  const { classes,} = this.props;
  const { isLoaded, hotelWasUpdated } = this.state;
  return (   
      <Paper className={classes.paper}>
       {!hotelWasUpdated 
            ? 
        <>
          <Typography color="primary" className={classes.header} variant="h5" id="modal-title">
            Update Hotel: {this.state.hotel.hotelname.en}
          </Typography>
          <Button color="primary" size="large" variant="contained" 
                className={classes.buttonUpdateHotel} onClick={this.handleModalUpdateHotel}>
            Update Hotel
          </Button> 
          <Button color="primary" size="large" variant="contained" 
                className={classes.buttonUpdateHotel} onClick={this.handleCloseUpdatePage}>
            Close
          </Button> 
        </>
            : 
          <Typography color="primary" className={classes.header} variant="h5" id="modal-title">
            Hotel : {this.state.hotel.hotelname.en} already updated!
          </Typography>}

        <MultiLangHotelInfo 
          value={this.state.valueTabForHotel} 
          handleChangeTab={this.handleChangeTabForHotel} 
          handleChangeHotelName={this.handleChangeHotelName}
          handleChangeDescription={this.handleChangeDescription}
          handleChangeCity={this.handleChangeCity}
          handleChangeRegion={this.handleChangeRegion}
          region={this.state.hotel.region}
          hotelname={this.state.hotel.hotelname}
          description={this.state.hotel.description}
          city={this.state.hotel.city}
          />

        <MainInfoHotel
          handleChangeFieldsModal = {this.handleChangeFieldsModal}
          hotel = {this.state.hotel}
        />

        <TypeOfAccommodation 
          handleChange={this.handleChangeAccomod}
          typeOfAccommod={this.state.hotel.typeOfAccommod}
        />

        {isLoaded && <ImgGalleryHotel 
          imgHotelPathArray={this.state.hotel.imgHotelPathArray}
          handleDeleteImgHotel={this.handleDeleteImgHotel}
        />}
        
        <UploadImagesForHotel 
          handleUploadImg={this.handleUploadImg} 
          imageshotel={this.state.imageshotel}
          handleAddImgToServer={this.handleAddImgToServer}
        />

        <Grid container spacing={8}>
          <Services 
            handleChangeOfService={this.handleChangeServices} 
            services={this.state.hotel.services} />
        </Grid>
        
        <Divider variant="fullWidth" /> 
          <Typography color="primary" variant="h6"> Rooms section </Typography>
       
        <Divider variant="fullWidth" /> 
          <ShowRooms 
            roomstype={this.state.hotel.roomstype} 
            deleteRoomFromTypeOfRoom={this.deleteRoomFromTypeOfRoom}
            editRoom={this.handleModalEditRoom}
            handleDeleteImgRoom={this.handleDeleteImgRoom}
            handleUploadImg={this.handleUploadImgForEditRoom} 
            imagesRoomEdit={this.state.imagesRoomEdit}
            uploadImgEditRoomOnServer={this.uploadImgEditRoomOnServer}
          />

        <Divider variant="fullWidth" /> 

        <Button color="primary" size="large" variant="contained" className={classes.button}
                onClick={this.handleModalAddRoom}>
          Add New Room
        </Button>

        <MultiLangRoomInfo 
            value={this.state.valueTabForRoom} 
            handleChangeTab={this.handleChangeTabForRoom} 
            handleChangeDescriptionRoom={this.handleChangeDescriptionRoom}
            handleChangeRoomName={this.handleChangeRoomName}
            handleChangeRoomRules={this.handleChangeRoomRules}
            handleChangeRoomFood={this.handleChangeRoomFood}
            handleChangeRoomBeds={this.handleChangeRoomBeds}
            roomname={this.state.roomname}
            description={this.state.descriptionRoom}
            rules={this.state.rules}
            food={this.state.food}
            beds={this.state.beds}
          />

        <MainInfoRoom handleChangeFieldsRoom={this.handleChangeFieldsRoom}/>

        <UploadImagesForRoom 
          handleUploadImg={this.handleUploadImgForRoom} 
          imagesroom={this.state.imagesroom} 
          />

        <Grid container spacing={8}>
          <ServicesRoom 
            handleChangeOfService={this.handleChangeServicesRoom} 
            services={this.state.servicesroom} 
            />
        </Grid>
        
        <ModalAddRoom 
          open={this.state.dialogAddRoom} 
          handleClose={this.handleModalAddRoom} 
          handleAddRoom={this.handleAddRoomToRoomsTypeObj} 
        />

        <ModalError
          open={this.state.errorDialogOpen} 
          error={this.state.errorMsg}
          handleClose={this.handleModalError} 
        />

        <ModalSuccess 
          open={this.state.succesDialogOpen} 
          success={this.state.successUpdateMsg} 
          handleClose={this.handleModalSuccess}
        />

        <ModalEditRoom 
          editRoomId={this.state.editRoomId}
          roomstype={this.state.hotel.roomstype}
          open={this.state.dialogEditRoom}
          value={this.state.valueTabForRoom} 
          handleClose={this.handleModalEditRoomClose} 
          handleChangeTab={this.handleChangeTabForRoom} 
          handleEditDescriptionRoom={this.handleEditDescriptionRoom}
          handleEditRoomName={this.handleEditRoomName}
          handleEditRoomRules={this.handleEditRoomRules}
          handleEditRoomFood={this.handleEditRoomFood}
          handleEditRoomBeds={this.handleEditRoomBeds}
          handleEditServicesRoom={this.handleEditServicesRoom}
          handleEditFieldsRoom={this.handleEditFieldsRoom}
          />
      </Paper>
      )
     }
}

UpdateHotel.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { hotelState } = state;
  return {
    hotelState
  };
}

const UpdateHotelCompose = compose(
  withStyles(styles),
  connect(mapStateToProps),
)(UpdateHotel);

export {UpdateHotelCompose as UpdateHotel};


