import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({
       forLegend : {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop : theme.spacing.unit*2,
       },
       forFormGroup : {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
       }
  });

function TypeOfAccommodation(props) {
    const { classes, typeOfAccommod} = props;
    return (
         <>
          <FormLabel component="legend" className ={classes.forLegend}>Type of Accommodation</FormLabel>
            <FormGroup row className ={classes.forFormGroup}>
            {typeOfAccommod.map((accommod, id) => (
                <FormControlLabel
                      control={
                        <Switch
                          checked={accommod.selected}
                          onChange={props.handleChange(accommod.name)}
                          value={accommod.name}
                          color="primary"
                        />
                      }
                      label={accommod.name}
                      key={id}
                    />
             ))}
                </FormGroup>
            </>
            );
}
TypeOfAccommodation.propTypes = {
    classes: PropTypes.object.isRequired,
    typeOfAccommod : PropTypes.array.isRequired,
  };
  
export default withStyles(styles)(TypeOfAccommodation);