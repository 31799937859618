import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    paperButton: {
      textAlign : "right",
    },
    // dense: {
    //   marginTop: 19,
   // }, 
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    textDesc : {
        height : 200,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    }
  });

function MainInfoRoom(props) {
    const { classes, room } = props;
       return (
    <form className={classes.container} autoComplete="off">
        <TextField
            required
            id="person_count"
            label="Person Count"
            variant="outlined"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            className={classes.textField}
            margin="normal"
            helperText="Example: 1, 2, 3 ... etc(Person in room)"
            value={room.personcount}
            onChange={props.handleEditFieldsRoom('personcount', props.id)}
        />
        <TextField
            required
            id="size"
            label="Size of room"
            variant="outlined"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            className={classes.textField}
            margin="normal"
            helperText="Example: 20 ... etc( size in m2)"
            value={room.size}
            onChange={props.handleEditFieldsRoom('size', props.id)}
        />
        <TextField
            required
            id="price"
            label="Price per night"
            variant="outlined"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            className={classes.textField}
            margin="normal"
            helperText="Example: 800, 1500 etc(we set price in UAH)"
            value={room.price}
            onChange={props.handleEditFieldsRoom('price', props.id)}
        />
    </form>
       )
  }

 MainInfoRoom.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(MainInfoRoom);

