import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {userService} from '../_services';
import ListOfUsers from './ListOfUsers';
import DeleteDialog from './DeleteDialog';
import AddUser from './AddUser';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  button: {
    margin: theme.spacing.unit,
  },
});

function validatePassword(password) {
                

  // Create an array and push all possible values that you want in password
  var matchedCase = [];
  matchedCase.push("[$@$!%*#?&]"); // Special Charector
  matchedCase.push("[A-Z]");      // Uppercase Alpabates
  matchedCase.push("[0-9]");      // Numbers
  matchedCase.push("[a-z]");     // Lowercase Alphabates

  // Check the conditions
  let ctr = 0;
  for (var i = 0; i < matchedCase.length; i++) {
      if (new RegExp(matchedCase[i]).test(password)) {
          ctr++;
      }
  }
 // ctr < 3 very weak
  return ctr
}

class Users extends Component {

  componentDidMount() {
    userService.getAll()
      .then(data => this.setState({users: data, isLoaded: true}))
      .catch(err => console.log("get all errror " , err));
 }

  constructor(props) {
    super(props);
    this.state = {
      users : [],
      openDialog: false,
      openAddUser: false,
      userId:'',
      isLoaded: false,
      password: '',
      username: '',
      passwordre: '',
      errMsg: '',
      submitted: false,
    };
  }

  handleOpenDeleteDialog = (userId) => event => {
    console.log(userId);
    this.setState({openDialog: true, userId: userId })
  }

  handleDeleteUser = (userId) => event => {
    userService.deleteUser(userId)
      .then(() => userService.getAll().then((data) => this.setState({users: data, isLoaded: true, })))
      .catch(err => this.setState({errMsg: err}));
    this.setState({openDialog: false,})
  }

  handleCloseDeleteDialog = event => {
    this.setState({openDialog: false,})
  }

  handleDialogAddUser = () => {
    this.setState({openAddUser: !this.state.openAddUser, errMsg: ''});
  }

  handleCreateUser = () => {
    console.log("handleCreateUser");

    const { username, password,passwordre } = this.state;
    if (!(username && password && passwordre)){
       return this.setState({submitted: false, errMsg:'Plz fill Name and Pass' });
     }
     if (password !== passwordre) {
      return this.setState({submitted: false, errMsg:'Password does not match'});
    }

    if(validatePassword(password)<=3) {
      return this.setState({submitted: false, errMsg:"Password is weak, use:[A-Z],[0-9],[$@$!%*#?&]"});
    }

    this.setState({ openAddUser: false, });
    userService.register(username, password)
      .then(() => userService.getAll()
            .then((data) => this.setState({errMsg: '', users: data, isLoaded: true, submitted: false ,
          password: '', passwordre:'', username: '',})))
      .catch(err => this.setState({errMsg: err}));
    
  }

  handleChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
  }

  render() {
  const { classes } = this.props;
    return (
      <>
      <Grid item xs={12}>
          <Paper className={classes.root}>
             <ListOfUsers users={this.state.users} handleOpenDeleteDialog={this.handleOpenDeleteDialog}/>
          </Paper>
      <Button     
        className={classes.button}                   
        size="small" 
        color="default"                         
        variant="contained" 
        onClick={this.handleDialogAddUser}
        >
          Add New User
      </Button>

      {this.state.errMsg && <Typography align="center" color="error" variant="body1">
                                   {this.state.errMsg}
                            </Typography>}

      <AddUser 
        openAddUser={this.state.openAddUser}
        handleDialogAddUser={this.handleDialogAddUser}
        handleCreateUser={this.handleCreateUser}
        passwordre={this.state.passwordre}
        password={this.state.password}
        username={this.state.username}
        handleChange={this.handleChange}
        submitted={this.state.submitted}
        errMsg={this.state.errMsg}
      />

      </Grid>
      <DeleteDialog 
        openDialog={this.state.openDialog} 
        userId={this.state.userId}
        handleDeleteUser={this.handleDeleteUser}
        handleCloseDialog={this.handleCloseDeleteDialog}
        />
    </>
      );
}}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Users);