import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    paperButton: {
      textAlign : "right",
    },
    // dense: {
    //   marginTop: 19,
   // }, 
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    textDesc : {
        height : 200,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    }
  });

function MainInfoHotel(props) {
    const { classes } = props;
       return (
        <form className={classes.container} autoComplete="off">
            <TextField
                required
                id="latitude"
                label="Latitude"
                variant="outlined"
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.textField}
                margin="normal"
                helperText="Correct: 41.40338"
                onChange={props.handleChangeFieldsModal('latitude')}
            />
            <TextField
                required
                id="longitude"
                label="Longitude"
                variant="outlined"
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.textField}
                margin="normal"
                helperText="Correct: 41.40338"
                onChange={props.handleChangeFieldsModal('longitude')}
            />
            <TextField
                required
                id="distance_from_city"
                label="Distance from city km"
                variant="outlined"
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.textField}
                margin="normal"
                onChange={props.handleChangeFieldsModal('distance_from_city')}
                helperText="From Kiev example : 122"
            />
            <TextField
                id="email"
                label="Email adress"
                type="email"
                name="email"
                autoComplete="email"
                className={classes.textField}
                onChange={props.handleChangeFieldsModal('email')}
                margin="normal"
                variant="outlined"
                helperText="example@gmail.com"
            />
            <TextField
                id="phone"
                label="Phone numbers"
                type="phone"
                name="phone"
                rows = {2}
                rowsMax = {3}
                multiline = {true}
                autoComplete="phone"
                className={classes.textField}
                onChange={props.handleChangeFieldsModal('phone')}
                margin="normal"
                variant="outlined"
                helperText="phones separated by commas"
            />
            <TextField
                id="site"
                label="Website address"
                type="site"
                name="site"
                autoComplete="site"
                className={classes.textField}
                onChange={props.handleChangeFieldsModal('site')}
                margin="normal"
                variant="outlined"
                helperText="example: www.mysite.com.ua"
            />
            <TextField
                id="place_id"
                label="place_id google Api"
                type="place_id"
                name="place_id"
                autoComplete="place_id"
                className={classes.textField}
                onChange={props.handleChangeFieldsModal('place_id')}
                margin="normal"
                variant="outlined"
                helperText="example: ChIJQVfF8cdz00ARm1I0_X9BQRc"
            />
        </form>
    )
}
// email phone site

MainInfoHotel.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(MainInfoHotel);

