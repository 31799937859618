import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    table: {
      minWidth: 700,
    },
    button: {
      margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
      }, 
  });

function AddUser(props) {
    const { classes, openAddUser,handleDialogAddUser, 
            handleCreateUser, handleChange, username, password, passwordre, submitted, errMsg} = props;
    return (
        <div>
        <Dialog
          open={openAddUser}
          onClose={handleDialogAddUser}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Add new User"}</DialogTitle>
                {errMsg && <Typography align="center" color="error" variant="body1">
                                   {errMsg}
                            </Typography>}
                    <TextField
                        id="username"
                        autoFocus
                        label="Username"
                        name="username" 
                        value={username}
                        variant="outlined"
                        margin="normal"
                        className={classes.textField}
                        onChange={handleChange}
                    />
                    <TextField
                        id="password"
                        label="Password"
                        name="password" 
                        value={password}
                        variant="outlined"
                        type="password"
                        margin="normal"
                        className={classes.textField}
                        onChange={handleChange}
                    />
                    <TextField
                        id="passwordre"
                        label="Password retype"
                        name="passwordre" 
                        value={passwordre}
                        variant="outlined"
                        type="password"
                        margin="normal"
                        className={classes.textField}
                        onChange={handleChange}
                    />
          <DialogActions>
            <Button className={classes.button} onClick={handleDialogAddUser} color="primary">
              Close
            </Button>
            {!submitted && <Button className={classes.button} onClick={handleCreateUser} color="primary">
                Add User
              </Button>}
          </DialogActions>
        </Dialog>
      </div>
    )
}

AddUser.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withStyles(styles)(AddUser);
