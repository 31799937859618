import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    dialotTitle : {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit * 2,
      //background : 'red',
    },
    dialogContent : {
      padding: theme.spacing.unit * 2,
    }
  });
function ModalAddRoom(props) {

  const { classes, fullScreen } = props;
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.dialotTitle} id="responsive-dialog-title">{'Attention!'}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContent}>
            Do you sure that you want Add Room for Hotel ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Exit
          </Button>
          <Button onClick={props.handleAddRoom} color="primary">
            Add Room
          </Button>
        </DialogActions>
    </Dialog>
      )
  }

  ModalAddRoom.propTypes = {
    classes: PropTypes.object.isRequired,
};
 
export default withStyles(styles)(ModalAddRoom);