import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  textField : {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  paper: {
    paddingTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginTop : theme.spacing.unit,
  }
});


function PlaceId(props) {
  const { classes,place_id } = props;
  return (
    <Paper className={classes.paper}>
        <TextField
          fullWidth
          id="place_id"
          label="place_id google Api"
          type="place_id"
          name="place_id"
          autoComplete="place_id"
          value={place_id}
          className={classes.textField}
          onChange={props.handleChangeFieldsModal('place_id')}
          margin="normal"
          variant="outlined"
          helperText="example: ChIJQVfF8cdz00ARm1I0_X9BQRc"
            />
    </Paper>
  );
}

PlaceId.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PlaceId);