import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';



const styles = theme => ({
    table: {
      minWidth: 700,
    },
    button: {
      margin: theme.spacing.unit,
    },
  });

function ListOfUsers(props) {
    const { classes, } = props;
    return (
        <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                      <TableCell align="left">User name</TableCell>
                      <TableCell align="left">Data created</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                </TableHead>
            <TableBody>
                {props.users.map((user, id) => (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                      {user.username}
                  </TableCell>
                  <TableCell component="th" scope="row">
                      {user.created}
                  </TableCell>
                    <TableCell align="justify">           
                        <Button                        
                          size="small" 
                          color="secondary"                         
                          variant="contained" 
                          className={classes.button} 
                          onClick={props.handleOpenDeleteDialog(user._id)}
                          >
                            Delete
                        </Button>
                    </TableCell>
                </TableRow>
                ))}
                </TableBody>
            </Table>   
    )
}

ListOfUsers.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  export default withStyles(styles)(ListOfUsers);

  